import React, { createContext, useReducer, useCallback, useMemo, useContext } from 'react'
import {
  RawIncomingEvent,
  RawDestinationEvent,
  ConfigurationObjectLink,
  DestinationConfig,
  ServiceConfig,
  StreamConfig,
} from 'types/cdp'
import { StoreEnvironment } from 'types'
import { cdpClient } from 'api/clients/cdpClient'
import { IncomingEventFilters } from 'components/chord-cdp/events/incoming/IncomingEventsToolbar'
import { DestinationEventFilters } from '../events/destinations/DestinationEventsToolbar'

interface CdpProviderProps {
  children: React.ReactNode
  tenantId: number
  storeId: number
  environment: StoreEnvironment
}

export type CdpApiError = {
  message: string
  error?: {
    issues?: Array<{
      code: string
      path: string[]
      message: string
    }>
  }
}

export type CdpProviderErrors = {
  incomingEvents: CdpApiError | null
  destinationEvents: CdpApiError | null
  streams: CdpApiError | null
  destinations: CdpApiError | null
  connectors: CdpApiError | null
  links: CdpApiError | null
  addDestination: CdpApiError | null
  editDestination: CdpApiError | null
  connectDestination: CdpApiError | null
  disconnectDestination: CdpApiError | null
  deleteDestination: CdpApiError | null
  testDestination: CdpApiError | null
}

type CdpLoadingStates = {
  incomingEvents: boolean
  destinationEvents: boolean
  streams: boolean
  destinations: boolean
  connectors: boolean
  links: boolean
  addDestination: boolean
  editDestination: boolean
  connectDestination: boolean
  disconnectDestination: boolean
  deleteDestination: boolean
  testDestination: boolean
}

type CdpState = {
  incomingEvents: RawIncomingEvent[]
  destinationEvents: RawDestinationEvent[]
  streams: StreamConfig[]
  destinations: DestinationConfig[]
  connectors: ServiceConfig[]
  links: ConfigurationObjectLink[]
  loadingStates: CdpLoadingStates
  errors: CdpProviderErrors
}

type CdpAction =
  | { type: 'SET_LOADING'; key: keyof CdpLoadingStates; value: boolean }
  | { type: 'SET_ERROR'; key: keyof CdpProviderErrors; value: CdpApiError | null }
  | { type: 'SET_DATA'; key: keyof Omit<CdpState, 'loadingStates' | 'errors'>; value: any }

const initialState: CdpState = {
  incomingEvents: [],
  destinationEvents: [],
  streams: [],
  destinations: [],
  connectors: [],
  links: [],
  loadingStates: {
    incomingEvents: false,
    destinationEvents: false,
    streams: false,
    destinations: false,
    connectors: false,
    links: false,
    addDestination: false,
    editDestination: false,
    connectDestination: false,
    disconnectDestination: false,
    deleteDestination: false,
    testDestination: false,
  },
  errors: {
    incomingEvents: null,
    destinationEvents: null,
    streams: null,
    destinations: null,
    connectors: null,
    links: null,
    addDestination: null,
    editDestination: null,
    connectDestination: null,
    disconnectDestination: null,
    deleteDestination: null,
    testDestination: null,
  },
}

function cdpReducer(state: CdpState, action: CdpAction): CdpState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          [action.key]: action.value,
        },
      }
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.key]: action.value,
        },
      }
    case 'SET_DATA':
      return {
        ...state,
        [action.key]: action.value,
      }
    default:
      return state
  }
}

type CdpContextValue = {
  state: CdpState
  dispatch: React.Dispatch<CdpAction>
  fetchIncomingEvents: (sourceId: string, filters: IncomingEventFilters) => Promise<void>
  fetchDestinationEvents: (destinationId: string, filters: DestinationEventFilters) => Promise<void>
  fetchStreams: () => Promise<void>
  fetchDestinations: () => Promise<void>
  fetchConnectors: () => Promise<void>
  fetchLinks: () => Promise<void>
  addDestination: (data: Record<string, any>) => Promise<boolean | CdpApiError>
  editDestination: (
    destinationId: string,
    data: Record<string, any>
  ) => Promise<boolean | CdpApiError>
  connectDestination: (streamId: string, destinationId: string) => Promise<boolean | CdpApiError>
  disconnectDestination: (streamId: string, destinationId: string) => Promise<boolean | CdpApiError>
  deleteDestination: (destinationId: string) => Promise<boolean | CdpApiError>
  testDestination: (data: Record<string, any>) => Promise<boolean | CdpApiError>
  clearError: (errorKey: keyof CdpProviderErrors) => void
}

const CdpContext = createContext<CdpContextValue | undefined>(undefined)

export const useCdp = () => {
  const context = useContext(CdpContext)
  if (!context) {
    throw new Error('useCdp must be used within a CdpProvider')
  }
  return context
}

export const CdpProvider: React.FC<CdpProviderProps> = ({
  children,
  tenantId,
  storeId,
  environment,
}) => {
  const [state, dispatch] = useReducer(cdpReducer, initialState)

  /**
   * Create a fetch action that will handle loading, error and data state for a given key.
   * This prevents the need to duplicate the same logic for each fetch action.
   */
  const createFetchAction =
    (
      key: keyof Omit<CdpState, 'loadingStates' | 'errors'>,
      apiCall: (...args: any[]) => Promise<any>
    ) =>
    async (...args: any[]) => {
      dispatch({ type: 'SET_LOADING', key, value: true })
      dispatch({ type: 'SET_ERROR', key, value: null })
      try {
        const data = await apiCall(...args)
        dispatch({ type: 'SET_DATA', key, value: data })
      } catch (error: any) {
        const cdpError: CdpApiError = {
          message: `An error occurred while fetching ${key}.`,
          error: error?.response?.data?.error,
        }
        dispatch({ type: 'SET_ERROR', key, value: cdpError })
      } finally {
        dispatch({ type: 'SET_LOADING', key, value: false })
      }
    }

  const fetchIncomingEvents = useCallback(
    createFetchAction('incomingEvents', (sourceId: string, filters: IncomingEventFilters) =>
      cdpClient.getIncomingEvents({
        tenantId,
        storeId,
        sourceId,
        environment,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        statusLevel: filters.statusLevel,
      })
    ),
    [tenantId, storeId, environment]
  )

  const fetchDestinationEvents = useCallback(
    createFetchAction(
      'destinationEvents',
      (destinationId: string, filters: DestinationEventFilters) =>
        cdpClient.getDestinationEvents({
          tenantId,
          storeId,
          destinationId,
          environment,
          dateFrom: filters.dateFrom,
          dateTo: filters.dateTo,
          statusLevel: filters.statusLevel,
          mode: filters.mode,
        })
    ),
    [tenantId, storeId, environment]
  )

  const fetchStreams = useCallback(
    createFetchAction('streams', () => cdpClient.getStreams({ tenantId, storeId, environment })),
    [tenantId, storeId, environment]
  )

  const fetchDestinations = useCallback(
    createFetchAction('destinations', () =>
      cdpClient.getDestinations({ tenantId, storeId, environment })
    ),
    [tenantId, storeId, environment]
  )

  const fetchConnectors = useCallback(
    createFetchAction('connectors', () =>
      cdpClient.getConnectors({ tenantId, storeId, environment })
    ),
    [tenantId, storeId, environment]
  )

  const fetchLinks = useCallback(
    createFetchAction('links', () => cdpClient.getLinks({ tenantId, storeId, environment })),
    [tenantId, storeId, environment]
  )

  const addDestination = useCallback(
    async (data: Record<string, any>) => {
      dispatch({ type: 'SET_LOADING', key: 'addDestination', value: true })
      dispatch({ type: 'SET_ERROR', key: 'addDestination', value: null })
      try {
        await cdpClient.addDestination({ tenantId, storeId, environment, destination: data })
        return true
      } catch (error: any) {
        const cdpError: CdpApiError = {
          message: 'An error occurred while adding destination.',
          error: error?.response?.data?.error,
        }
        dispatch({ type: 'SET_ERROR', key: 'addDestination', value: cdpError })
        return cdpError
      } finally {
        dispatch({ type: 'SET_LOADING', key: 'addDestination', value: false })
      }
    },
    [tenantId, storeId, environment]
  )

  const editDestination = useCallback(
    async (destinationId: string, data: Record<string, any>) => {
      dispatch({ type: 'SET_LOADING', key: 'editDestination', value: true })
      dispatch({ type: 'SET_ERROR', key: 'editDestination', value: null })
      try {
        await cdpClient.editDestination({
          tenantId,
          storeId,
          environment,
          destinationId,
          destination: data,
        })
        return true
      } catch (error: any) {
        const cdpError: CdpApiError = {
          message: 'An error occurred while editing destination.',
          error: error?.response?.data?.error,
        }
        dispatch({ type: 'SET_ERROR', key: 'editDestination', value: cdpError })
        return cdpError
      } finally {
        dispatch({ type: 'SET_LOADING', key: 'editDestination', value: false })
      }
    },
    [tenantId, storeId, environment]
  )

  const connectDestination = useCallback(
    async (streamId: string, destinationId: string) => {
      dispatch({ type: 'SET_LOADING', key: 'connectDestination', value: true })
      dispatch({ type: 'SET_ERROR', key: 'connectDestination', value: null })
      try {
        await cdpClient.connectDestination({
          tenantId,
          storeId,
          environment,
          streamId,
          destinationId,
        })
        return true
      } catch (error: any) {
        const cdpError: CdpApiError = {
          message: 'An error occurred while connecting destination.',
          error: error?.response?.data?.error,
        }
        dispatch({ type: 'SET_ERROR', key: 'connectDestination', value: cdpError })
        return cdpError
      } finally {
        dispatch({ type: 'SET_LOADING', key: 'connectDestination', value: false })
      }
    },
    [tenantId, storeId, environment]
  )

  const disconnectDestination = useCallback(
    async (streamId: string, destinationId: string) => {
      dispatch({ type: 'SET_LOADING', key: 'disconnectDestination', value: true })
      dispatch({ type: 'SET_ERROR', key: 'disconnectDestination', value: null })
      try {
        await cdpClient.disconnectDestination({
          tenantId,
          storeId,
          environment,
          streamId,
          destinationId,
        })
        return true
      } catch (error: any) {
        const cdpError: CdpApiError = {
          message: 'An error occurred while disconnecting destination.',
          error: error?.response?.data?.error,
        }
        dispatch({ type: 'SET_ERROR', key: 'disconnectDestination', value: cdpError })
        return cdpError
      } finally {
        dispatch({ type: 'SET_LOADING', key: 'disconnectDestination', value: false })
      }
    },
    [tenantId, storeId, environment]
  )

  const deleteDestination = useCallback(
    async (destinationId: string) => {
      dispatch({ type: 'SET_LOADING', key: 'deleteDestination', value: true })
      dispatch({ type: 'SET_ERROR', key: 'deleteDestination', value: null })
      try {
        await cdpClient.deleteDestination({
          tenantId,
          storeId,
          environment,
          destinationId,
        })
        return true
      } catch (error: any) {
        const cdpError: CdpApiError = {
          message: 'An error occurred while deleting destination.',
          error: error?.response?.data?.error,
        }
        dispatch({ type: 'SET_ERROR', key: 'deleteDestination', value: cdpError })
        return cdpError
      } finally {
        dispatch({ type: 'SET_LOADING', key: 'deleteDestination', value: false })
      }
    },
    [tenantId, storeId, environment]
  )

  const testDestination = useCallback(
    async (data: Record<string, any>) => {
      dispatch({ type: 'SET_LOADING', key: 'testDestination', value: true })
      dispatch({ type: 'SET_ERROR', key: 'testDestination', value: null })
      try {
        const testResult = await cdpClient.testDestination({
          tenantId,
          storeId,
          environment,
          destination: data,
        })

        if (testResult.ok) {
          return true
        } else {
          const cdpError: CdpApiError = {
            message: 'An unknown error occurred while testing the destination.',
            error: {
              issues: [
                { code: 'UNKNOWN_ERROR', path: [], message: testResult.error || 'Unknown error' },
              ],
            },
          }

          dispatch({ type: 'SET_ERROR', key: 'testDestination', value: cdpError })
          return false
        }
      } catch (error: any) {
        const cdpError: CdpApiError = {
          message:
            error?.response?.data?.error?.message ||
            'An error occurred while testing the destination.',
          error: error?.response?.data?.error,
        }

        dispatch({ type: 'SET_ERROR', key: 'testDestination', value: cdpError })
        return cdpError
      } finally {
        dispatch({ type: 'SET_LOADING', key: 'testDestination', value: false })
      }
    },
    [tenantId, storeId, environment]
  )

  const clearError = useCallback((errorKey: keyof CdpProviderErrors) => {
    dispatch({ type: 'SET_ERROR', key: errorKey, value: null })
  }, [])

  const contextValue: CdpContextValue = useMemo(
    () => ({
      state,
      dispatch,
      fetchIncomingEvents,
      fetchDestinationEvents,
      fetchStreams,
      fetchDestinations,
      fetchConnectors,
      fetchLinks,
      addDestination,
      editDestination,
      connectDestination,
      disconnectDestination,
      deleteDestination,
      testDestination,
      clearError,
    }),
    [
      state,
      dispatch,
      fetchIncomingEvents,
      fetchDestinationEvents,
      fetchStreams,
      fetchDestinations,
      fetchConnectors,
      fetchLinks,
      addDestination,
      editDestination,
      connectDestination,
      disconnectDestination,
      deleteDestination,
      testDestination,
      clearError,
    ]
  )

  return <CdpContext.Provider value={contextValue}>{children}</CdpContext.Provider>
}
