import React, { useContext, useMemo } from 'react'
import { SplitFactory } from '@splitsoftware/splitio'

import { EnvVarConfig } from 'utils/envVarConfig'
import { SplitAdapter } from 'utils/experiments/adapters/split'
import ExperimentManager from '../utils/experiments/manager'
import { useAuthData } from 'redux/state/auth'
import { useTenantsData } from 'redux/state/tenants'

export interface ISplitContext {
  factory?: SplitIO.IBrowserSDK
  adapter?: SplitAdapter
  manager?: ExperimentManager
}

const splitKey = EnvVarConfig.REACT_APP_SPLIT_KEY
const defaultValue: Partial<ISplitContext> = {
  adapter: undefined,
  factory: undefined,
  manager: undefined,
}

export const SplitContext = React.createContext<Partial<ISplitContext>>(defaultValue)
SplitContext.displayName = 'SplitContext'

export const SplitProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()
  const {
    state: { user },
  } = useAuthData()

  const value = useMemo<Partial<ISplitContext>>(() => {
    if (!user?.email || !splitKey || !currentTenant?.id || !currentStore?.id) {
      return defaultValue
    }

    const factory = SplitFactory({
      core: {
        authorizationKey: splitKey,
        key: user.email,
      },
    }) as SplitIO.IBrowserSDK

    const adapter = new SplitAdapter(factory)
    const manager = new ExperimentManager({
      adapter,
      user,
      tenantId: currentTenant.id,
      tenantName: currentTenant.name,
      storeId: currentStore.id,
    })

    return { factory, adapter, manager }
  }, [user, currentTenant?.id, currentStore?.id])

  return <SplitContext.Provider value={value}>{children}</SplitContext.Provider>
}

export function useSplit() {
  return useContext(SplitContext)
}
