import React from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Construction } from '@chordco/component-library/components/icons/standardIcons/Construction'

interface MaintenanceBannerProps {
  maintenanceWindow: string
  navOpen: boolean
  onClose: () => void
}

export const MaintenanceBanner: React.FC<MaintenanceBannerProps> = ({
  maintenanceWindow,
  navOpen,
  onClose,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: 'warning.main',
        color: 'common.black',
        paddingRight: 2,
        paddingLeft: navOpen ? 2 : 8,
        paddingY: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        position: 'relative',
      }}
    >
      <Construction scale={38} />
      <Box flex="1">
        <Typography variant="h6">Scheduled Maintenance</Typography>
        <Typography variant="body1">
          A maintenance window is scheduled for {maintenanceWindow}. During this time, the
          application will be unavailable.
        </Typography>
      </Box>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 12,
          right: 12,
          color: 'inherit',
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  )
}
