import React, { useState } from 'react'
import { H3, Label } from '@chordco/component-library'
import styled from 'styled-components'
import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { DataSettingsSchedulingMonths, DataSettingsFiscalYearPeriods } from 'types'

type SchedulingProps = {
  startOfYear: string
  fiscalPeriod: string
}

const months: DataSettingsSchedulingMonths[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const fiscalYearPeriods: DataSettingsFiscalYearPeriods[] = ['Broadcast', 'ISO', '4-4-5']

export const Scheduling: React.FC<SchedulingProps> = ({ startOfYear, fiscalPeriod }) => {
  const [selectedYearStart, setSelectedYearStart] = useState<string>(startOfYear)
  const [selectedFiscalPeriodStart, setSelectedFiscalPeriodStart] = useState<string>(fiscalPeriod)

  const handleYearStartChange = event => {
    setSelectedYearStart(event.target.value)
  }

  const handleFiscalPeriodStartChange = event => {
    setSelectedFiscalPeriodStart(event.target.value)
  }

  return (
    <>
      <SchedulingContainer>
        <FiscalYearContainer>
          <TitleContainer>
            <H3>Scheduling</H3>
          </TitleContainer>
          <Label> Fiscal Year Start Date </Label>
          <FormControl sx={{ minWidth: 350 }} size="small">
            <Select
              id="start-of-year-picker"
              value={selectedYearStart}
              onChange={handleYearStartChange}
            >
              {months.map(month => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FiscalYearContainer>
        <FiscalPeriodContainer>
          <Label> Fiscal Periods </Label>
          <FormControl sx={{ minWidth: 350 }} size="small">
            <Select
              id="fiscal-period-picker"
              value={selectedFiscalPeriodStart}
              onChange={handleFiscalPeriodStartChange}
            >
              {fiscalYearPeriods.map(fiscalYear => (
                <MenuItem key={fiscalYear} value={fiscalYear}>
                  {fiscalYear}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FiscalPeriodContainer>
      </SchedulingContainer>
      <BoxContainer>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
      </BoxContainer>
    </>
  )
}

const SchedulingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  padding-top: 25px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding-bottom: 10px;
  color: ${p => p.theme.ContentPrimary};
`

const FiscalYearContainer = styled.div`
  flex-grow: 1;
  color: ${p => p.theme.ContentTertiary};
`

const FiscalPeriodContainer = styled.div`
  align-self: flex-end;
  padding-top: 20px;
  color: ${p => p.theme.ContentTertiary};
`
const BoxContainer = styled.div`
  padding-top: 40px;
  margin-left: 10px;
`
