import React from 'react'
import { Switch, TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const gaDeviceDestinationCredentialsConfig: CredentialsConfig = {
  measurementIds: {
    description: `Measurement ID of your Google Analytics 4 properties. <a href='https://support.google.com/analytics/answer/9539598?hl=en' target='_blank' rel='noreferrer noopener'>How to find</a>`,
    renderComponent: TextField,
    componentProps: {
      label: 'Measurement ID',
      type: 'text',
      required: true,
    },
  },
  autoPageView: {
    description: `Rely on <a href='https://support.google.com/analytics/answer/9216061#page_view' target='_blank' rel='noopener noreferer'>Enhanced event measurement</a> to track page views.`,
    renderComponent: Switch,
    componentProps: {
      label: 'Auto Page View',
      defaultChecked: false,
    },
  },
}

export const GaDeviceDestinationCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={gaDeviceDestinationCredentialsConfig} />
)
