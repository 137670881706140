import React from 'react'
import SnippetEditor from 'components/chord-cdp/common/SnippetEditor'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const tagCredentialsConfig: CredentialsConfig = {
  code: {
    description: 'Code to insert into site page in <b>html</b> or <b>javascript</b> format',
    renderComponent: SnippetEditor,
    componentProps: {
      label: 'Code',
      required: true,
    },
  },
}

export const TagCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={tagCredentialsConfig} />
)
