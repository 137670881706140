import React from 'react'
import { createId } from '@paralleldrive/cuid2'
import KeyValueField from './KeyValueField'
import { Box } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { Button } from '@chordco/component-library'

type KeyValueListProps = {
  type: string
  state?: { id: string; key: string; value: string; toggle: boolean }[]
  setState: React.Dispatch<React.SetStateAction<{ key: string; value: string }[]>>
}

const KeyValueList: React.FC<KeyValueListProps> = ({ type, state = [], setState }) => {
  const keyValueForms = state.map((param, index) => (
    <KeyValueField key={param.id} type={type} index={index} state={state} setState={setState} />
  ))

  const handleAddNewItem = () => {
    const newParam = { id: createId(), key: '', value: '' }
    setState([...state, newParam])
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {keyValueForms}
      <Box display="flex" justifyContent="flex-start">
        <Button
          purpose="tertiary"
          icon={AddIcon}
          onClick={handleAddNewItem}
          name="Add New Parameter"
          location="CDP Add or Edit Destination"
        >
          {`Add New ${type}`}
        </Button>
      </Box>
    </Box>
  )
}

export default KeyValueList
