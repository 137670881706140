import React from 'react'
import { TextField, Select, Switch } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const amplitudeCredentialsConfig: CredentialsConfig = {
  key: {
    description: 'Amplitude Project API Key.',
    renderComponent: TextField,
    componentProps: {
      label: 'Project API Key',
      type: 'text',
      required: true,
    },
  },
  enableGroupAnalytics: {
    description: `Build an analysis around aggregated units of measure like accounts, charts, or order IDs. Requires The Amplitude Accounts add-on. <a href='https://help.amplitude.com/hc/en-us/articles/115001765532-Account-level-reporting-in-Amplitude' target='_blank' rel='noreferrer noopener'>Learn more.</a>`,
    renderComponent: Switch,
    componentProps: {
      label: 'Enable Group Analytics',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  groupType: {
    description:
      'Group type is the abstract type of whatever our group represents (e.g. accounts, charts, or order IDs).',
    renderComponent: TextField,
    componentProps: {
      label: 'Group Type',
      type: 'text',
      defaultValue: 'company',
    },
  },
  enableAnonymousUserProfiles: {
    description: 'If enabled, anonymous users will be tracked in Amplitude',
    renderComponent: Switch,
    componentProps: {
      label: 'Enable Anonymous User Profiles',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  dataResidency: {
    description: 'Data Residency',
    renderComponent: Select,
    componentProps: {
      label: 'Data Residency',
      defaultValue: 'US',
      fullWidth: true,
      required: true,
      options: [
        { id: 'US', value: 'US' },
        { id: 'EU', value: 'EU' },
      ],
    },
  },
  sessionWindow: {
    description: 'Session window in minutes',
    renderComponent: TextField,
    componentProps: {
      label: 'Session Window (minutes)',
      type: 'number',
      defaultValue: 30,
    },
  },
}

export const AmplitudeCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={amplitudeCredentialsConfig} />
)

