import React from 'react'
import { RadioGroup, FormControlLabel, Radio, Box } from '@mui/material'
import { editor } from 'monaco-editor'
import { CodeEditor } from 'components/chord-cdp/common/CodeEditor'

/**
 * See tagDestination comments, due to limitations of the react-jsonschema-form we can't use
 * an object as a value, so we have to use a string.
 */
export type SnippetEditorValue = string

type SupportedLanguages = 'html' | 'javascript' | 'json' | 'text'
export type SnippetEditorParsed = {
  lang: SupportedLanguages
  code?: string
}

export type SnippetEditorProps = {
  value: SnippetEditorValue
  languages?: SupportedLanguages[]
  height?: number
  onChange: (value: SnippetEditorValue) => void
  monacoOptions?: editor.IStandaloneEditorConstructionOptions
  //automatically fold code on provided level of indentation on editor mount
  foldLevel?: number
}

/**
 * To support historical values which were plain strings
 * @param val
 */
function parse(val: string) {
  try {
    const j = JSON.parse(val)
    if (j.lang) {
      return j
    } else {
      return { code: val, lang: 'json' }
    }
  } catch (e) {
    return { code: val, lang: 'javascript' }
  }
}

const SnippetEditor: React.FC<SnippetEditorProps> = props => {
  const [value, setValue] = React.useState<SnippetEditorValue>(props.value)

  const valueParsed = value
    ? (parse(value) as SnippetEditorParsed)
    : { lang: 'javascript', code: '' }
  const singleLanguage = props.languages && props.languages.length === 1

  return (
    <Box>
      {!singleLanguage && (
        <Box className="text-right mb-4">
          <RadioGroup
            row
            value={valueParsed.lang}
            onChange={e => {
              const newValue = JSON.stringify({
                ...valueParsed,
                lang: e.target.value.toLowerCase() as SupportedLanguages,
              })
              setValue(newValue)
              props.onChange(newValue)
            }}
          >
            {(props.languages || ['text']).map(lang => (
              <FormControlLabel key={lang} value={lang} control={<Radio />} label={lang} />
            ))}
          </RadioGroup>
        </Box>
      )}
      <Box className={`border border-textDisabled`}>
        <CodeEditor
          language={valueParsed.lang?.toLowerCase() || 'html'}
          height={props.height ? `${props.height}px` : '500px'}
          value={valueParsed.code || ''}
          onChange={code => {
            if (singleLanguage) {
              props.onChange(code || '')
            } else {
              const newValue = JSON.stringify({ ...valueParsed, code })
              setValue(newValue)
              props.onChange(newValue)
            }
          }}
          foldLevel={props.foldLevel}
          monacoOptions={{
            lineNumbers: 'off',
            ...(props.monacoOptions || {}),
          }}
        />
      </Box>
    </Box>
  )
}

export default SnippetEditor
