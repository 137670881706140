import React, { useState } from 'react'
import { Card } from '../common/Card'
import { CardContent, Header, TextContainer } from 'components/analytics/reports/common'
import { DestinationConfig } from 'types/cdp'
import { H4 } from '@chordco/component-library'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import { coreDestinationsMap } from '../destinations'
import {
  Language,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import { blue } from '@mui/material/colors'

type DestinationCardProps = {
  destination: DestinationConfig
  selected?: boolean
  connected?: boolean
  onEditDestination: (destination: DestinationConfig) => void
  onConnectDestination: (destination: DestinationConfig) => void
  onDisconnectDestination: (destination: DestinationConfig) => void
  onDeleteDestination: (destination: DestinationConfig) => void
  canEditDestination?: boolean
  canConnectDestination?: boolean
  canDisconnectDestination?: boolean
  canDeleteDestination?: boolean
}

export const DestinationCard: React.FC<DestinationCardProps> = ({
  destination,
  selected,
  connected,
  onEditDestination,
  onConnectDestination,
  onDisconnectDestination,
  onDeleteDestination,
  canEditDestination = false,
  canConnectDestination = false,
  canDisconnectDestination = false,
  canDeleteDestination = false,
}) => {
  const icon = coreDestinationsMap[destination.destinationType]?.icon
  const size = { width: 36, height: 36 }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    onEditDestination(destination)
    handleMenuClose()
  }

  const handleConnect = () => {
    onConnectDestination(destination)
    handleMenuClose()
  }

  const handleDisconnect = () => {
    onDisconnectDestination(destination)
    handleMenuClose()
  }

  const handleDelete = () => {
    onDeleteDestination(destination)
    handleMenuClose()
  }

  return (
    <Card forceHover={selected}>
      <CardContent>
        <Header>
          {icon ? <Box sx={size}>{icon}</Box> : <Language sx={{ ...size, color: blue[500] }} />}
          <TextContainer>
            <H4>{destination.name}</H4>
          </TextContainer>
        </Header>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleMenuOpen} size="small">
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose} sx={{ elevation: 1 }}>
            <MenuItem onClick={handleEdit} disabled={!canEditDestination}>
              <EditIcon fontSize="small" style={{ marginRight: 8 }} />
              Edit
            </MenuItem>
            {connected ? (
              <MenuItem onClick={handleDisconnect} disabled={!canDisconnectDestination}>
                <LinkOffIcon fontSize="small" style={{ marginRight: 8 }} />
                Disconnect
              </MenuItem>
            ) : (
              <MenuItem onClick={handleConnect} disabled={!canConnectDestination}>
                <LinkIcon fontSize="small" style={{ marginRight: 8 }} />
                Connect
              </MenuItem>
            )}
            <MenuItem onClick={handleDelete} disabled={!canDeleteDestination}>
              <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </CardContent>
    </Card>
  )
}
