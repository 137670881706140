import React from 'react'
import { TextField } from '@mui/material'
import { blockStorageSettingsConfig, CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const gcsCredentialsConfig: CredentialsConfig = {
  accessKey: {
    description: 'Google Access Key',
    renderComponent: TextField,
    componentProps: {
      label: 'Access Key',
      type: 'text',
      placeholder: 'Enter your Google Access Key',
      required: true,
    },
  },
  bucket: {
    description: 'GCS Bucket Name',
    renderComponent: TextField,
    componentProps: {
      label: 'Bucket Name',
      type: 'text',
      placeholder: 'e.g., my-gcs-bucket',
      required: true,
    },
  },
  ...blockStorageSettingsConfig,
}

export const GcsCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={gcsCredentialsConfig} />
)
