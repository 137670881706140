import React, { useState } from 'react'
import { Box, TextField } from '@mui/material'
import { Button } from '@chordco/component-library'
import { Trash } from '@chordco/component-library/components/icons/standardIcons/Trash'

type KeyValueFieldProps = {
  type: string
  index: number
  state: { key: string; value: string }[]
  setState: React.Dispatch<React.SetStateAction<{ key: string; value: string }[]>>
}

const KeyValueField: React.FC<KeyValueFieldProps> = ({ type, index, state, setState }) => {
  const [key, setKey] = useState(state[index].key)
  const [value, setValue] = useState(state[index].value)

  const handleKeyInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = [...state]
    newState[index].key = event.target.value
    setKey(newState[index].key)
    setState(newState)
  }

  const handleValueInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = [...state]
    newState[index].value = event.target.value
    setValue(newState[index].value)
    setState(newState)
  }

  const handleDelete = () => {
    const newState = [...state]
    newState.splice(index, 1)
    setState(newState)
  }

  return (
    <Box key={index} display="flex" alignItems="center" gap={2}>
      <TextField
        size="small"
        label={`${type} ${index + 1}`}
        value={key}
        onChange={handleKeyInput}
        fullWidth
      />
      <TextField
        size="small"
        label={`Value ${index + 1}`}
        value={value}
        onChange={handleValueInput}
        fullWidth
      />
      <Button
        purpose="delete-secondary"
        icon={Trash}
        onClick={handleDelete}
        name="Delete Parameter"
        location="CDP Add or Edit Destination"
      />
    </Box>
  )
}

export default KeyValueField
