import React, { useState } from 'react'
import { Box, MenuItem, Select, Typography, FormControl, Alert } from '@mui/material'
import { Language } from '@mui/icons-material'
import { blue } from '@mui/material/colors'
import { ConfigurationObjectLink, DestinationConfig, StreamConfig } from 'types/cdp'
import { coreDestinationsMap } from 'components/chord-cdp/destinations'
import { useCdp } from 'components/chord-cdp/provider/CdpProvider'
import { Error } from 'components/chord-cdp/overview/Error'
import { FormSheet } from 'components/common'

type ConnectDestinationFormProps = {
  selectedDestination: DestinationConfig
  streams: StreamConfig[]
  destinations: DestinationConfig[]
  links: ConfigurationObjectLink[]
  onDestinationConnected: () => void
  onClose: () => void
}

const ConnectDestinationForm: React.FC<ConnectDestinationFormProps> = ({
  selectedDestination,
  streams,
  destinations,
  links,
  onDestinationConnected,
  onClose,
}) => {
  const {
    state: { loadingStates, errors },
    connectDestination,
    clearError,
  } = useCdp()

  const [dstId, setDstId] = useState(selectedDestination.id)
  const [srcId, setSrcId] = useState(streams[0].id)

  const existingLink = links.some(l => l.fromId === srcId && l.toId === dstId)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const src = streams.find(s => s.id === srcId)
    const dst = destinations.find(d => d.id === dstId)
    if (src && dst) {
      const result = await connectDestination(src.id, dst.id)
      if (result === true) onDestinationConnected()
    }
  }

  const handleClose = () => {
    clearError('connectDestination')
    onClose()
  }

  return (
    <FormSheet
      onSubmit={handleSubmit}
      onClose={handleClose}
      customTitle="Connect Destination"
      isEdit={true}
      isLarge={true}
      isLoading={loadingStates.connectDestination}
      submitDisabled={existingLink || loadingStates.connectDestination}
      name="Connect Destination"
      location="Chord CDP"
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl fullWidth>
          <Typography variant="h6" mb={1}>
            Select source to connect
          </Typography>
          <Select size="small" value={srcId} onChange={e => setSrcId(e.target.value)}>
            {streams.map(src => (
              <MenuItem key={src.id} value={src.id}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ width: 36, height: 36 }}>
                    <Language sx={{ width: '100%', height: '100%', color: blue[500] }} />
                  </Box>
                  <Typography variant="body1">{src.name}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Typography variant="h6" mb={1}>
            Select destination to connect
          </Typography>
          <Select size="small" value={dstId} onChange={e => setDstId(e.target.value)}>
            {destinations.map(dst => {
              const icon = coreDestinationsMap[dst.destinationType]?.icon
              return (
                <MenuItem key={dst.id} value={dst.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ width: 36, height: 36 }}>
                      {icon ? (
                        icon
                      ) : (
                        <Language sx={{ width: '100%', height: '100%', color: blue[500] }} />
                      )}
                    </Box>
                    <Typography variant="body1">{dst.name}</Typography>
                  </Box>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        {existingLink && (
          <Alert severity="warning" variant="outlined">
            This connection already exists. Create a new connection if you want to change the
            source.
          </Alert>
        )}

        {errors.connectDestination && <Error error={errors.connectDestination} />}
      </Box>
    </FormSheet>
  )
}

export default ConnectDestinationForm
