import React, { useEffect, useMemo, useState } from 'react'
import { Section } from 'components/common'
import { useIsMobile } from 'hooks'
import { RawIncomingEvent } from 'types/cdp'
import { useCdp } from 'components/chord-cdp/provider/CdpProvider'
import {
  IncomingEventFilters,
  IncomingEventsToolbar,
} from 'components/chord-cdp/events/incoming/IncomingEventsToolbar'
import { IncomingEventDrawer } from 'components/chord-cdp/events/incoming/IncomingEventDrawer'
import { IncomingEventsTable } from 'components/chord-cdp/events/incoming/IncomingEventsTable'
import { mapIncomingEvent, parseIncomingEventRows } from 'components/chord-cdp/events/utils'

export interface ParsedIncomingEventRow {
  date: string
  type?: string
  event: string
  pagePath: string
  pageUrl: string
  summary: {
    email?: string
    userId?: string
    host?: string
    referringDomain?: string
    anonymousId?: string
  }
  rawEvent?: RawIncomingEvent
}

export const IncomingEvents: React.FC = () => {
  const isMobile = useIsMobile()

  const {
    state: { streams, destinations, incomingEvents, links, loadingStates },
    fetchStreams,
    fetchDestinations,
    fetchIncomingEvents,
    fetchLinks,
  } = useCdp()

  const [filters, setFilters] = useState<IncomingEventFilters>(() => ({
    source: streams[0],
    statusLevel: 'all',
    dateFrom: '',
    dateTo: '',
  }))

  const [selectedRow, setSelectedRow] = useState<ParsedIncomingEventRow | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchStreams(), fetchDestinations(), fetchLinks()])
    }

    fetchData()
  }, [fetchStreams, fetchDestinations, fetchLinks])

  useEffect(() => {
    const refetchIncomingEvents = async () => {
      if (filters.source?.id) {
        await fetchIncomingEvents(filters.source.id, filters)
      }
    }

    refetchIncomingEvents()
  }, [filters, fetchIncomingEvents])

  const parsedRows = useMemo(() => parseIncomingEventRows(incomingEvents), [incomingEvents])

  const handleFiltersChange = (updatedFilters: IncomingEventFilters) => {
    setFilters(updatedFilters)
  }

  const handleRefresh = () => {
    if (filters.source?.id) {
      fetchIncomingEvents(filters.source.id, filters)
    } else {
      fetchStreams()
    }
  }

  const handleRowClick = (row: ParsedIncomingEventRow) => {
    setSelectedRow(row)
  }

  const isLoading =
    loadingStates.streams ||
    loadingStates.destinations ||
    loadingStates.links ||
    loadingStates.incomingEvents

  return (
    <Section isMobile={isMobile} hasBorder={false} loading={isLoading} title="Incoming Events">
      {!isLoading && (
        <>
          <IncomingEventsToolbar
            sources={streams}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            onRefresh={handleRefresh}
          />
          <IncomingEventsTable rows={parsedRows} onRowClick={handleRowClick} />
        </>
      )}

      {selectedRow && selectedRow.rawEvent && (
        <IncomingEventDrawer
          event={mapIncomingEvent(selectedRow.rawEvent)}
          onClose={() => setSelectedRow(null)}
          mappedDestinations={links}
          destinations={destinations}
        />
      )}
    </Section>
  )
}
