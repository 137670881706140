import {
  ConfigTestResult,
  StreamConfigResponse,
  DestinationConfigResponse,
  ConnectorConfigResponse,
  LinkConfigResponse,
  RawIncomingEvent,
  RawDestinationEvent,
} from 'types/cdp'
import { hubClient, AxiosRequestConfig } from 'api/http'
import { StoreEnvironment } from 'types'

export interface CdpClient {
  getStreams: (data: {
    tenantId: number
    storeId: number
    environment: StoreEnvironment
  }) => Promise<StreamConfigResponse>
  getDestinations: (data: {
    tenantId: number
    storeId: number
    environment: StoreEnvironment
  }) => Promise<DestinationConfigResponse>
  getConnectors: (data: {
    tenantId: number
    storeId: number
    environment: StoreEnvironment
  }) => Promise<ConnectorConfigResponse>
  getLinks: (data: {
    tenantId: number
    storeId: number
    environment: StoreEnvironment
  }) => Promise<LinkConfigResponse>
  getIncomingEvents: (data: {
    tenantId: number
    storeId: number
    sourceId: string
    environment: StoreEnvironment
    dateFrom?: string
    dateTo?: string
    statusLevel?: 'all' | 'errors'
    limit?: number
  }) => Promise<RawIncomingEvent[]>
  getDestinationEvents: (data: {
    tenantId: number
    storeId: number
    destinationId: string
    environment: StoreEnvironment
    dateFrom?: string
    dateTo?: string
    statusLevel?: 'all' | 'errors'
    mode?: 'batch' | 'stream'
    limit?: number
  }) => Promise<RawDestinationEvent[]>

  addDestination: (data: {
    tenantId: number
    storeId: number
    destination: Record<string, any>
    environment: StoreEnvironment
  }) => Promise<DestinationConfigResponse>

  editDestination: (data: {
    tenantId: number
    storeId: number
    destinationId: string
    destination: Record<string, any>
    environment: StoreEnvironment
  }) => Promise<DestinationConfigResponse>

  connectDestination: (data: {
    tenantId: number
    storeId: number
    streamId: string
    destinationId: string
    environment: StoreEnvironment
  }) => Promise<void>

  disconnectDestination: (data: {
    tenantId: number
    storeId: number
    streamId: string
    destinationId: string
    environment: StoreEnvironment
  }) => Promise<void>

  deleteDestination: (data: {
    tenantId: number
    storeId: number
    destinationId: string
    environment: StoreEnvironment
  }) => Promise<void>

  testDestination: (data: {
    tenantId: number
    storeId: number
    destination: Record<string, any>
    environment: StoreEnvironment
  }) => Promise<ConfigTestResult>
}

export const cdpClient: CdpClient = {
  getStreams: ({ tenantId, storeId, environment }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/jitsu/streams?env=${environment}`),
  getDestinations: ({ tenantId, storeId, environment }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/jitsu/destinations?env=${environment}`),
  getConnectors: ({ tenantId, storeId, environment }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/jitsu/connectors?env=${environment}`),
  getLinks: ({ tenantId, storeId, environment }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/jitsu/links?env=${environment}`),
  getIncomingEvents: ({
    tenantId,
    storeId,
    sourceId,
    environment,
    dateFrom,
    dateTo,
    statusLevel = 'all',
    limit = 100,
  }) => {
    const params = new URLSearchParams()

    params.append('env', environment)

    if (limit) {
      params.append('limit', limit.toString())
    }
    if (dateFrom) {
      params.append('dateFrom', dateFrom)
    }
    if (dateTo) {
      params.append('dateTo', dateTo)
    }
    if (statusLevel && statusLevel !== 'all') {
      params.append('levels', statusLevel)
    }

    return hubClient.get(
      `tenants/${tenantId}/stores/${storeId}/jitsu/streams/${sourceId}/incoming_events?${params.toString()}`
    )
  },
  getDestinationEvents: ({
    tenantId,
    storeId,
    destinationId,
    environment,
    dateFrom,
    dateTo,
    statusLevel = 'all',
    mode = 'batch',
    limit = 100,
  }) => {
    const params = new URLSearchParams()

    params.append('env', environment)

    if (limit) {
      params.append('limit', limit.toString())
    }
    if (dateFrom) {
      params.append('dateFrom', dateFrom)
    }
    if (dateTo) {
      params.append('dateTo', dateTo)
    }
    if (statusLevel && statusLevel !== 'all') {
      params.append('levels', statusLevel)
    }
    if (mode) {
      params.append('mode', mode)
    }

    // Skip the default camelization for this request since we need to maintain the raw response
    // data (otherwise 'schema' keys will be camelized)
    const config: AxiosRequestConfig = {
      skipCamelize: true,
    }

    return hubClient.get(
      `tenants/${tenantId}/stores/${storeId}/jitsu/destinations/${destinationId}/events?${params.toString()}`,
      config
    )
  },
  addDestination: ({ tenantId, storeId, destination, environment }) => {
    // Skip the default decamelization, we need to maintain the raw payload for Jitsu's API
    const config: AxiosRequestConfig = { skipDecamelize: true }
    const params = new URLSearchParams()
    params.append('env', environment)

    return hubClient.post(
      `tenants/${tenantId}/stores/${storeId}/jitsu/destinations?${params.toString()}`,
      { destination },
      config
    )
  },
  editDestination: ({ tenantId, storeId, destinationId, destination, environment }) => {
    // Skip the default decamelization, we need to maintain the raw payload for Jitsu's API
    const config: AxiosRequestConfig = { skipDecamelize: true }
    const params = new URLSearchParams()
    params.append('env', environment)

    return hubClient.put(
      `tenants/${tenantId}/stores/${storeId}/jitsu/destinations/${destinationId}?${params.toString()}`,
      { destination },
      config
    )
  },
  connectDestination: ({ tenantId, storeId, streamId, destinationId, environment }) => {
    // Skip the default decamelization, we need to maintain the raw payload for Jitsu's API
    const config: AxiosRequestConfig = { skipDecamelize: true }

    const params = new URLSearchParams()
    params.append('env', environment)

    return hubClient.post(
      `tenants/${tenantId}/stores/${storeId}/jitsu/connect?${params.toString()}`,
      { from_id: streamId, to_id: destinationId },
      config
    )
  },
  disconnectDestination: ({ tenantId, storeId, streamId, destinationId, environment }) => {
    // Skip the default decamelization, we need to maintain the raw payload for Jitsu's API
    const config: AxiosRequestConfig = { skipDecamelize: true }

    const params = new URLSearchParams()
    params.append('env', environment)

    return hubClient.delete(
      `tenants/${tenantId}/stores/${storeId}/jitsu/disconnect?${params.toString()}`,
      {
        data: { from_id: streamId, to_id: destinationId },
        ...config,
      }
    )
  },
  deleteDestination: ({ tenantId, storeId, destinationId, environment }) => {
    // Skip the default decamelization, we need to maintain the raw payload for Jitsu's API
    const config: AxiosRequestConfig = { skipDecamelize: true }

    const params = new URLSearchParams()
    params.append('env', environment)

    return hubClient.delete(
      `tenants/${tenantId}/stores/${storeId}/jitsu/destinations/${destinationId}?${params.toString()}`,
      config
    )
  },
  testDestination: ({ tenantId, storeId, destination, environment }) => {
    // Skip the default decamelization, we need to maintain the raw payload for Jitsu's API
    const config: AxiosRequestConfig = { skipDecamelize: true }
    const params = new URLSearchParams()
    params.append('env', environment)

    return hubClient.post(
      `tenants/${tenantId}/stores/${storeId}/jitsu/destinations/test?${params.toString()}`,
      { destination },
      config
    )
  },
}
