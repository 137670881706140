import React from 'react'
import {
  Alert,
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { CdpApiError } from 'components/chord-cdp/provider/CdpProvider'

type ErrorProps = {
  error: CdpApiError
  onClose?: () => void
}

export const Error: React.FC<ErrorProps> = ({ error, onClose }) => {
  const [open, setOpen] = React.useState(true)

  const message = error.message || 'An unknown error occurred.'
  const issues = error.error?.issues || []

  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Collapse in={open}>
        <Alert
          severity="error"
          role="alert"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {message}
          </Typography>
          {issues.length > 0 && (
            <List dense>
              {issues.map((issue, idx) => (
                <ListItem key={idx} disablePadding>
                  <ListItemText
                    primary={`Path: ${issue.path.join('.')}`}
                    secondary={issue.message}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Alert>
      </Collapse>
    </Box>
  )
}

export default Error
