import React from 'react'
import { TextField, Select } from '@mui/material'
import {
  blockStorageSettingsConfig,
  CredentialsConfig,
  s3Regions,
} from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const s3CredentialsConfig: CredentialsConfig = {
  authenticationMethod: {
    description: `S3 authentication method: <a target='_blank' rel='noopener noreferrer' href='https://docs.jitsu.com/destinations/block-storage/s3#advanced-iam-role-for-jitsu'>IAM Role based</a> or Access Key`,
    renderComponent: Select,
    componentProps: {
      label: 'Authentication Method',
      defaultValue: 'accessKey',
      fullWidth: true,
      required: true,
      options: [
        { id: 'accessKey', value: 'Access Key' },
        { id: 'iam', value: 'IAM Role' },
      ],
    },
  },
  region: {
    description: 'S3 Region',
    renderComponent: Select,
    componentProps: {
      label: 'S3 Region',
      defaultValue: 'us-east-1',
      fullWidth: true,
      required: true,
      options: s3Regions.map(region => ({ id: region, value: region })),
    },
  },
  roleARN: {
    description: `IAM role ARN. <a target='_blank' rel='noopener noreferrer' href='https://docs.jitsu.com/destinations/warehouse/redshift#advanced-iam-role-for-jitsu'>How to create</a>`,
    renderComponent: TextField,
    componentProps: {
      label: 'Role ARN',
      type: 'text',
      placeholder: 'e.g., arn:aws:iam::123456789012:role/RoleName',
    },
  },
  externalID: {
    description: 'IAM external ID',
    renderComponent: TextField,
    componentProps: {
      label: 'IAM External ID',
      type: 'text',
      placeholder: 'e.g., 12345678-1234-5678-1234-567812345678',
    },
  },
  accessKeyId: {
    description: 'S3 Access Key Id',
    renderComponent: TextField,
    componentProps: {
      label: 'S3 Access Key ID',
      type: 'text',
      placeholder: 'e.g., AKIAIOSFODNN7EXAMPLE',
    },
  },
  secretAccessKey: {
    description: 'S3 Secret Access Key',
    renderComponent: TextField,
    componentProps: {
      label: 'S3 Secret Access Key',
      type: 'password',
      placeholder: 'Enter your secret access key',
    },
  },
  bucket: {
    description: 'S3 Bucket Name',
    renderComponent: TextField,
    componentProps: {
      label: 'S3 Bucket Name',
      type: 'text',
      placeholder: 'e.g., my-data-bucket',
      required: true,
    },
  },
  endpoint: {
    description: 'Custom endpoint of S3-compatible server',
    renderComponent: TextField,
    componentProps: {
      label: 'Custom Endpoint',
      type: 'text',
      placeholder: 'e.g., https://custom-s3-endpoint.com',
    },
  },
  ...blockStorageSettingsConfig,
}

const getVisibilityRules = (values: Record<string, any>) => {
  return {
    roleARN: values.authenticationMethod === 'iam',
    externalID: values.authenticationMethod === 'iam',
    accessKeyId: values.authenticationMethod !== 'iam',
    secretAccessKey: values.authenticationMethod !== 'iam',
    endpoint: values.authenticationMethod !== 'iam',
  }
}

export const S3CredentialsForm = props => (
  <GenericCredentialsForm
    {...props}
    config={s3CredentialsConfig}
    getVisibilityRules={getVisibilityRules}
  />
)
