import React from 'react'
import styled from 'styled-components'
import { Button, H2, standardIcons } from '@chordco/component-library'

const Plus = standardIcons.Plus

interface OverviewHeaderProps {
  title: string
  hasData: boolean
  onAddNew: () => void
  canAddNew?: boolean
}

const OverviewHeader: React.FC<OverviewHeaderProps> = ({
  title,
  hasData,
  onAddNew,
  canAddNew = false,
}) => {
  return (
    <HeaderContainer>
      <H2>{title}</H2>
      <ActionsContainer>
        {hasData && canAddNew && (
          <Button
            purpose="secondary"
            onClick={onAddNew}
            icon={Plus}
            name={`Add ${title}`}
            location="CDP Overview Header"
          >
            Add
          </Button>
        )}
      </ActionsContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export default OverviewHeader
