import React from 'react'
import { H3 } from '@chordco/component-library'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

function createData(
  changed: string,
  date: string,
  time: string,
  userName: string,
  userRole: string
) {
  return { changed, date, time, userName, userRole }
}

const rows = [
  createData('Time Zone Changed', 'January 15, 2024', '8:30 AM', 'User Name', 'Super Admin'),
  createData('Time Zone Changed', 'January 15, 2024', '8:30 AM', 'User Name', 'Super Admin'),
  createData('Time Zone Changed', 'January 15, 2024', '8:30 AM', 'User Name', 'Super Admin'),
  createData('Time Zone Changed', 'January 15, 2024', '8:30 AM', 'User Name', 'Super Admin'),
  createData('Time Zone Changed', 'January 15, 2024', '8:30 AM', 'User Name', 'Super Admin'),
]

export default function BasicTable() {
  return (
    <ChangeLogContainer>
      <TitleContainer>
        <H3>Setting Change Log</H3>
      </TitleContainer>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, borderTop: 0, borderRight: 0, borderLeft: 0, borderBottom: 0 }}
          size="medium"
          aria-label="changelog table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Changed</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>Time</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>By User</TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'left' }}>User Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                key={row.changed}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.changed}
                </TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.time}</TableCell>
                <TableCell align="left">{row.userName}</TableCell>
                <TableCell align="left">{row.userRole}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ChangeLogContainer>
  )
}

const ChangeLogContainer = styled.div`
  top: 10px;
  position: float;
  left: 10px;
  padding-bottom: 40px;
  padding-top 20px;
`

const TitleContainer = styled.div`
  position: relative;
  top: 10px;
  padding-bottom: 20px;
`