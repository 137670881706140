import React, { useState } from 'react'
import { H3, Label } from '@chordco/component-library'
import styled from 'styled-components'
import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { DataSettingsSchedulingDays } from 'types'

type WeekDefinitionProps = {
  startOfWeek: string
}

const weekDays: DataSettingsSchedulingDays[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const WeekDefinition: React.FC<WeekDefinitionProps> = ({ startOfWeek }) => {
  const [selectedWeekStart, setSelectedWeekStart] = useState<string>(startOfWeek)

  const handleWeekStartChange = event => {
    setSelectedWeekStart(event.target.value)
  }

  return (
    <>
      <TimezoneContainer>
        <TitleContainer>
          <H3>Week Definition</H3>
        </TitleContainer>
        <Label>Start week on </Label>
        <FormControl sx={{ minWidth: 350 }} size="small">
          <Select
            id="week-of-week-picker"
            value={selectedWeekStart}
            onChange={handleWeekStartChange}
          >
            {weekDays.map(weekDay => (
              <MenuItem key={weekDay} value={weekDay}>
                {weekDay}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BoxContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
        </BoxContainer>
      </TimezoneContainer>
    </>
  )
}

const TimezoneContainer = styled.div`
  position: relative;
  top: 10px;
  margin-left: 10px;
  padding-bottom: 25px;
`

const TitleContainer = styled.div`
  position: relative;
  top: 10px;
  padding-bottom: 20px;
`

const BoxContainer = styled.div`
  padding-top: 40px;
`
