import React from 'react'
import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import KeyValueList from 'components/chord-cdp/common/KeyValueList'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const mysqlCredentialsConfig: CredentialsConfig = {
  host: {
    description: 'Mysql host',
    renderComponent: TextField,
    componentProps: {
      label: 'Host',
      type: 'text',
      required: true,
    },
  },
  port: {
    description: 'Mysql port',
    renderComponent: TextField,
    componentProps: {
      label: 'Port',
      type: 'number',
      defaultValue: 3306,
    },
  },
  database: {
    description: 'Mysql database name',
    renderComponent: TextField,
    componentProps: {
      label: 'Database',
      type: 'text',
      required: true,
    },
  },
  username: {
    description: 'Mysql username',
    renderComponent: TextField,
    componentProps: {
      label: 'Username',
      type: 'text',
      required: true,
    },
  },
  password: {
    description: 'Mysql password',
    renderComponent: TextField,
    componentProps: {
      label: 'Password',
      type: 'password',
      required: true,
    },
  },
  parameters: {
    description:
      'Please note: TLS is a required parameter. Valid TLS (Transport Layer Security) protocol values are: <code>true</code>, <code>false</code>, <code>skip-verify</code>, <code>preferred</code>',
    renderComponent: KeyValueList,
    componentProps: {
      label: 'Parameters',
      defaultValue: [
        {
          key: 'tls',
          value: 'preferred',
        },
      ],
    },
  },
}

export const MysqlCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={mysqlCredentialsConfig} />
)
