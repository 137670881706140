import React from 'react'
import { TextField, Switch } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const hubSpotCredentialsConfig: CredentialsConfig = {
  accessToken: {
    description: [
      "To obtain an access secret, go to <b>Settings » Account Setup » Private Apps</b>, create a new private app, copy the <b>Access token</b>. See <a href='https://developers.hubspot.com/docs/api/private-apps'>detailed guide</a>.",
      'Please make sure to grant an application all read and write permissions under CRM section',
    ].join('\n'),
    renderComponent: TextField,
    componentProps: {
      label: 'Access Token',
      type: 'text',
      required: true,
    },
  },
  sendPageViewEvents: {
    description:
      'When enabled, Jitsu will send page view events to HubSpot (only events with a known email).',
    renderComponent: Switch,
    componentProps: {
      label: 'Send Page View Events',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  autoCreateCustomProperties: {
    description: `When enabled, we will automatically create HubSpot <a href='https://knowledge.hubspot.com/properties/create-and-edit-properties'>custom properties</a> for Contacts and Companies to capture every new trait. Otherwise, only known properties are sent.`,
    renderComponent: Switch,
    componentProps: {
      label: 'Auto Create Custom Properties',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
}

export const HubSpotCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={hubSpotCredentialsConfig} />
)
