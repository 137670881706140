import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useLookerData } from 'redux/state/looker'
import { Section } from 'components/common'
import { Loading } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { useIsLoading } from 'redux/utils'
import { AudienceCard } from './AudienceCard'
import { AvailableAudience } from 'types'
import EmptyAudiences from 'components/settings/integrations/requests/EmptyAudiences'
import { useTenantsData } from 'redux/state/tenants'
import { useAuthData } from 'redux/state/auth'
import ScrollableContainer from 'components/common/ScrollableContainer'

const PrebuiltAudiences: React.FC = () => {
  const history = useHistory()
  const isMobile = useIsMobile()

  const {
    state: { currentTenant },
  } = useTenantsData()

  const {
    state: { user },
  } = useAuthData()

  if (!user) throw new Error('Hub User not found!')
  if (!currentTenant) throw new Error('No current tenant')

  const {
    getAvailableAudiences,
    state: { availableAudiences },
  } = useLookerData()

  const isLoadingAudiences = useIsLoading('getAvailableAudiences')

  useEffect(() => {
    getAvailableAudiences && getAvailableAudiences()
  }, [])

  const handleSelectAudience = (audience: AvailableAudience) => {
    history.push({
      pathname: `/audiences/prebuilt-audiences/${audience.slug}`,
      state: {
        id: audience.url.split('/').pop(),
        title: audience.name,
        description: audience.description,
      },
    })
  }

  return (
    <ScrollableContainer height="calc(100vh - 120px)" padding="16px 0px 32px 0px">
      <Section isMobile={isMobile} hasBorder={false}>
        {isLoadingAudiences && <Loading />}

        {!isLoadingAudiences && (
          <>
            {availableAudiences.audiences.length === 0 && (
              <EmptyAudiences tenant={currentTenant} user={user} />
            )}

            {availableAudiences.audiences.length > 0 && (
              <AudiencesContainer>
                {availableAudiences.audiences.map(audience => (
                  <AudienceCard
                    key={audience.slug}
                    audience={audience}
                    onSelectAudience={handleSelectAudience}
                  />
                ))}
              </AudiencesContainer>
            )}
          </>
        )}
      </Section>
    </ScrollableContainer>
  )
}

const AudiencesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 16px;
`

export default PrebuiltAudiences
