import { Achievement } from './Achievement'
import { AddUser } from './AddUser'
import { Alert } from './Alert'
import { AlertInfo } from './AlertInfo'
import { AlertTriangle } from './AlertTriangle'
import { AlertWarning } from './AlertWarning'
import { ArrowDiagonal } from './ArrowDiagonal'
import { ArrowDown } from './ArrowDown'
import { ArrowDownCircle } from './ArrowDownCircle'
import { ArrowLeft } from './ArrowLeft'
import { ArrowLeftCircle } from './ArrowLeftCircle'
import { ArrowRight } from './ArrowRight'
import { ArrowRightCircle } from './ArrowRightCircle'
import { ArrowUp } from './ArrowUp'
import { ArrowUpCircle } from './ArrowUpCircle'
import { AtSymbol } from './AtSymbol'
import { BarChart } from './BarChart'
import { Bell } from './Bell'
import { BodySvg } from './BodySvg'
import { Bold } from './Bold'
import { Bullet } from './Bullet'
import { Burger } from './Burger'
import { BurgerArrow } from './BurgerArrow'
import { Calendar } from './Calendar'
import { Cart } from './Cart'
import { CDPLogo } from './CDPLogo'
import { Channel } from './Channel'
import { ChatBubble } from './ChatBubble'
import { Check } from './Check'
import { CheckCircle } from './CheckCircle'
import { Checkmark } from './Checkmark'
import { ChevronDown } from './ChevronDown'
import { ChevronLeft } from './ChevronLeft'
import { ChevronRight } from './ChevronRight'
import { ChevronRightDouble } from './ChevronRightDouble'
import { ChevronUp } from './ChevronUp'
import { CircleCheck } from './CircleCheck'
import { Clipboard } from './Clipboard'
import { CloseX } from './CloseX'
import { Company } from './Company'
import { Compare } from './Compare'
import { Compass } from './Compass'
import { Construction } from './Construction'
import { ModelAmplifications } from './ModelAmplifications'
import { Copy } from './Copy'
import { CornerLeftDown } from './CornerLeftDown'
import { Cube } from './Cube'
import { Dashboard } from './Dashboard'
import { DataSources } from './DataSources'
import { DataActivations } from './DataActivations'
import { Drag } from './Drag'
import { Duplicate } from './Duplicate'
import { Edit } from './Edit'
import { Exit } from './Exit'
import { ExitCircle } from './ExitCircle'
import { Eye } from './Eye'
import { File } from './File'
import { FilterIcon } from './FilterIcon'
import { Future } from './Future'
import { Gear } from './Gear'
import { Gift } from './Gift'
import { Group } from './Group'
import { Heading } from './Heading'
import { Help } from './Help'
import { Home } from './Home'
import { IconLeft } from './IconLeft'
import { IconSvg } from './IconSvg'
import { Image } from './Image'
import { Import } from './Import'
import { Integration } from './Integration'
import { Italic } from './Italic'
import { KeyResult } from './KeyResult'
import { KeyResultsIcon } from './KeyResultsIcon'
import { LargeGear } from './LargeGear'
import { Layers } from './Layers'
import { LineChart } from './LineChart'
import { ListViewIcon } from './ListViewIcon'
import { Lock } from './Lock'
import { LogOut } from './LogOut'
import { Mail } from './Mail'
import { Maximize } from './Maximize'
import { MetricBuilder } from './MetricBuilder'
import { Metrics } from './Metrics'
import { Minus } from './Minus'
import { MountainFlag } from './MountainFlag'
import { NumberedList } from './NumberedList'
import { Objective } from './Objective'
import { OpenLink } from './OpenLink'
import { Overflow } from './Overflow'
import { Past } from './Past'
import { Pause } from './Pause'
import { PieChart } from './PieChart'
import { Pin } from './Pin'
import { Play } from './Play'
import { PlayOutline } from './PlayOutline'
import { Plus } from './Plus'
import { Plug } from './Plug'
import { PlusCircle } from './PlusCircle'
import { Range } from './Range'
import { Refresh } from './Refresh'
import { Reports } from './Reports'
import { Search } from './Search'
import { Send } from './Send'
import { Share } from './Share'
import { Sort } from './Sort'
import { Square } from './Square'
import { Star } from './Star'
import { Stop } from './Stop'
import { Strike } from './Strike'
import { Table } from './Table'
import { Tag } from './Tag'
import { Target } from './Target'
import { Team } from './Team'
import { Template } from './Template'
import { ThickCheck } from './ThickCheck'
import { Time } from './Time'
import { Todo } from './Todo'
import { Tool } from './Tool'
import { Trash } from './Trash'
import { Tree } from './Tree'
import { TreeViewIcon } from './TreeViewIcon'
import { TrendingUp } from './TrendingUp'
import { Trophy } from './Trophy'
import { Underline } from './Underline'
import { User } from './User'
import { Unpin } from './Unpin'
import { VerticalCollapse } from './VerticalCollapse'
import { VerticalExpand } from './VerticalExpand'
import { Encyclopedia } from './Encyclopedia'
import { GoogleLogo } from './GoogleLogo'
import { Sync } from './Sync'
import { BrandChampions } from './BrandChampions'
import { ProductLoyalists } from './ProductLoyalists'
import { NewCustomers30 } from './NewCustomers30'
import { NewCustomers90 } from './NewCustomers90'
import { CouponEntrants } from './CouponEntrants'
import { CustomersToBe } from './CustomersToBe'
import { AlmostPurchasers } from './AlmostPurchasers'
import { CustomersAtRisk } from './CustomersAtRisk'
import { MotivatedCustomers } from './MotivatedCustomers'
import { Download } from './Download'
import { Upload } from './Upload'
import { GenericAudience } from './GenericAudience'
import { Unpublish } from './Unpublish'
import { Publish } from './Publish'

export const standardIcons = {
  Achievement,
  AddUser,
  Alert,
  AlertInfo,
  AlertTriangle,
  AlertWarning,
  AlmostPurchasers,
  ArrowDiagonal,
  ArrowDown,
  ArrowDownCircle,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUp,
  ArrowUpCircle,
  AtSymbol,
  BarChart,
  Bell,
  BodySvg,
  Bold,
  Bullet,
  Burger,
  BurgerArrow,
  BrandChampions,
  Calendar,
  Cart,
  CDPLogo,
  Channel,
  ChatBubble,
  Check,
  Checkmark,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronRightDouble,
  ChevronUp,
  CircleCheck,
  Clipboard,
  CloseX,
  Company,
  Compare,
  Compass,
  Construction,
  ModelAmplifications,
  Copy,
  CornerLeftDown,
  CouponEntrants,
  Cube,
  CustomersAtRisk,
  CustomersToBe,
  Dashboard,
  DataSources,
  DataActivations,
  Drag,
  Duplicate,
  Edit,
  Exit,
  ExitCircle,
  Eye,
  File,
  FilterIcon,
  Future,
  Gear,
  GenericAudience,
  Gift,
  GoogleLogo,
  Group,
  Heading,
  Help,
  Home,
  IconLeft,
  IconSvg,
  Image,
  Import,
  Integration,
  Italic,
  KeyResult,
  KeyResultsIcon,
  LargeGear,
  Layers,
  LineChart,
  ListViewIcon,
  Lock,
  LogOut,
  Mail,
  Maximize,
  MetricBuilder,
  Metrics,
  Minus,
  MotivatedCustomers,
  MountainFlag,
  NumberedList,
  NewCustomers30,
  NewCustomers90,
  Objective,
  OpenLink,
  Overflow,
  Past,
  Pause,
  PieChart,
  Pin,
  Play,
  PlayOutline,
  Plus,
  Plug,
  PlusCircle,
  ProductLoyalists,
  Publish,
  Range,
  Refresh,
  Reports,
  Search,
  Send,
  Share,
  Sort,
  Square,
  Star,
  Stop,
  Strike,
  Sync,
  Table,
  Tag,
  Target,
  Team,
  Template,
  ThickCheck,
  Time,
  Todo,
  Tool,
  Trash,
  Tree,
  TreeViewIcon,
  TrendingUp,
  Trophy,
  Underline,
  Unpublish,
  User,
  VerticalCollapse,
  VerticalExpand,
  Encyclopedia,
  Download,
  Upload,
  Unpin,
}
