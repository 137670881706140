import React from 'react'
import { MappedConnectionLink } from 'components/chord-cdp/events/utils'
import { coreDestinationsMap } from 'components/chord-cdp/destinations'
import { Api, ArrowRightAltRounded, Language } from '@mui/icons-material'
import { Box } from '@mui/material'
import { blue } from '@mui/material/colors'

type DestinationEventDropdownItemProps = {
  item: MappedConnectionLink
}

export const DestinationEventDropdownItem: React.FC<DestinationEventDropdownItemProps> = ({
  item,
}) => {
  const styles = { width: 16, height: 16, color: blue[500] }

  const SourceIcon = item.source?.type === 'stream' ? <Language sx={styles} /> : <Api sx={styles} />

  const DestinationIcon =
    item.destination?.destinationType &&
    coreDestinationsMap[item.destination.destinationType]?.icon ? (
      coreDestinationsMap[item.destination.destinationType]?.icon
    ) : (
      <Language sx={styles} />
    )

  const sourceName = item.source?.name ?? 'DELETED'
  const destinationName = item.destination?.name ?? 'DELETED'

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} key={item.id}>
      {SourceIcon}
      {sourceName}
      <ArrowRightAltRounded />
      <Box sx={{ display: 'flex', alignItems: 'center', ...styles }}>{DestinationIcon}</Box>
      {destinationName}
    </Box>
  )
}
