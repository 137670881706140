import React, { useState } from 'react'
import styled from 'styled-components'
import { AvailableUserReport } from 'types'
import { QuickstartCard } from './QuickstartCard'
import { useHistory } from 'react-router-dom'
import { QuickstartToolbar } from './QuickstartToolbar'
import { H2 } from '@chordco/component-library'
import {
  QuickstartCategory,
  QuickstartFilters,
  QuickstartType,
} from 'redux/state/quickstartsFilter'
import { NoResults } from './NoResults'
import { usePinReport } from 'components/looker/usePinReport'
import { UnpinModal } from './UnpinModal'
import ScrollableContainer from 'components/common/ScrollableContainer'

type QuickstartsProps = {
  dashboards: AvailableUserReport[]
  looks: AvailableUserReport[]
  onRefresh: () => void
}

export const Quickstarts: React.FC<QuickstartsProps> = ({ dashboards, looks, onRefresh }) => {
  const history = useHistory()
  const { pin, isLoading } = usePinReport()

  const [showUnpinModal, setShowUnpinModal] = useState<AvailableUserReport | null>(null)

  const [filteredReports, setFilteredReports] = useState<AvailableUserReport[]>([
    ...dashboards,
    ...looks,
  ])

  const handleSelectReport = (report: AvailableUserReport) => {
    history.push({
      pathname: `/analytics/quickstarts/${report.slug}`,
      state: {
        id: report.id,
        title: report.label,
        description: report.description,
        type: report.metricType,
      },
    })
  }

  const handlePinReport = async (report: AvailableUserReport) => {
    await pin({ reportId: report.id, reportType: report.metricType })
  }

  const handleUnpinReport = (report: AvailableUserReport) => {
    setShowUnpinModal(report)
  }

  const handleFiltersChange = (filters: QuickstartFilters) => {
    let filtered = [...dashboards, ...looks]

    switch (filters.selectedType) {
      case QuickstartType.DASHBOARDS:
        filtered = dashboards
        break
      case QuickstartType.LOOKS:
        filtered = looks
        break
      case QuickstartType.ALL_REPORTS:
      default:
        // Use all reports
        break
    }

    if (
      filters.selectedCategory &&
      filters.selectedCategory !== QuickstartCategory.ALL_CATEGORIES
    ) {
      filtered = filtered.filter(report => report.folderName === filters.selectedCategory)
    }

    // Filter by searchTerm if provided
    if (filters.searchTerm && filters.searchTerm.trim() !== '') {
      const searchTerm = filters.searchTerm.toLowerCase()
      filtered = filtered.filter(
        report =>
          report.label?.toLowerCase().includes(searchTerm) ||
          report.description?.toLowerCase().includes(searchTerm) ||
          report.folderName?.toLowerCase().includes(searchTerm)
      )
    }

    // Sort reports alphabetically
    const sorted = [...filtered].sort((a, b) => a.label.localeCompare(b.label))

    setFilteredReports(sorted)
  }

  const getUniqueCategories = (): string[] => {
    const allReports = [...dashboards, ...looks]

    const categories = new Set(
      allReports.map(report => report.folderName).filter(category => category !== null)
    )

    return Array.from(categories) as string[]
  }

  const uniqueCategories = getUniqueCategories()

  const closeUnpinModal = () => setShowUnpinModal(null)

  return (
    <ScrollableContainer height="calc(100vh - 120px)" padding="16px 0px 32px 0px">
      <QuickstartToolbar
        onFiltersChange={handleFiltersChange}
        onRefresh={onRefresh}
        categories={[QuickstartCategory.ALL_CATEGORIES, ...uniqueCategories]}
      />

      <StyledH2>Quickstarts</StyledH2>

      {filteredReports.length > 0 && (
        <ReportsContainer>
          {filteredReports.map((dashboard, index) => (
            <QuickstartCard
              key={`${dashboard.slug}-${index}`}
              report={dashboard}
              onSelectReport={handleSelectReport}
              onPinReport={handlePinReport}
              onUnpinReport={handleUnpinReport}
              isLoading={isLoading}
            />
          ))}
        </ReportsContainer>
      )}

      {filteredReports.length === 0 && <NoResults />}

      {showUnpinModal && (
        <UnpinModal report={showUnpinModal} onUnpin={closeUnpinModal} onClose={closeUnpinModal} />
      )}
    </ScrollableContainer>
  )
}

const ReportsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  grid-gap: 16px;
`

const StyledH2 = styled(H2)`
  margin-bottom: 16px;
`
