import React from 'react'
import { TextField } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const segmentCredentialsConfig: CredentialsConfig = {
  apiBase: {
    description: 'Segment API Base',
    renderComponent: TextField,
    componentProps: {
      label: 'API Base',
      type: 'text',
      defaultValue: 'https://api.segment.io/v1',
      required: true,
    },
  },
  writeKey: {
    description:
      'To get an API Key you need to add the HTTP API source to your Segment workspace. Write Key can be found on the HTTP API source Overview page.',
    renderComponent: TextField,
    componentProps: {
      label: 'Write Key',
      type: 'text',
      required: true,
    },
  },
}

export const SegmentCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={segmentCredentialsConfig} />
)
