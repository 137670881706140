import { TextField, Select, Switch } from '@mui/material'
import KeyValueList from 'components/chord-cdp/common/KeyValueList'
import PasswordField from 'components/chord-cdp/common/PasswordField'
import SnippetEditor from 'components/chord-cdp/common/SnippetEditor'

// This is a type definition for the components that can be rendered in the Destination form
type AllowedComponents =
  | typeof TextField
  | typeof PasswordField
  | typeof Select
  | typeof Switch
  | typeof KeyValueList
  | typeof SnippetEditor

type SelectOption = {
  id: string
  value: string
}

export type FormFieldConfig = {
  description: string
  renderComponent: AllowedComponents
  componentProps?: {
    label?: string
    defaultValue?: any
    defaultChecked?: boolean
    multiline?: boolean
    options?: SelectOption[]
    [key: string]: any // Catch-all for any additional props
  }
}

export const s3Regions = [
  'us-west-1',
  'us-west-2',
  'us-east-2',
  'us-east-1',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-north-1',
  'me-south-1',
  'sa-east-1',
  'us-gov-east-1',
  'us-gov-west-1',
] as const

export type CredentialsConfig = Record<string, FormFieldConfig>

// Common config for S3 and GCS block storage settings
export const blockStorageSettingsConfig: CredentialsConfig = {
  folder: {
    description: 'Folder in the block storage bucket where files will be stored',
    renderComponent: TextField,
    componentProps: {
      label: 'Folder',
      type: 'text',
      placeholder: 'e.g., /data/files',
    },
  },
  format: {
    description: `Format of the files stored in the block storage: <b>ndjson</b> - Newline Delimited JSON, <b>ndjson_flat</b> - Newline Delimited JSON flattened, <b>csv</b> - CSV`,
    renderComponent: Select,
    componentProps: {
      label: 'Format',
      defaultValue: 'ndjson',
      fullWidth: true,
      required: true,
      options: [
        { id: 'ndjson', value: 'Newline Delimited JSON' },
        { id: 'ndjson_flat', value: 'Newline Delimited JSON (Flattened)' },
        { id: 'csv', value: 'CSV' },
      ],
    },
  },
  compression: {
    description: `Compression algorithm used for the files stored in the block storage: <b>gzip</b> - GZIP, <b>none</b> - no compression.`,
    renderComponent: Select,
    componentProps: {
      label: 'Compression',
      defaultValue: 'gzip',
      fullWidth: true,
      required: true,
      options: [
        { id: 'none', value: 'None (no compression)' },
        { id: 'gzip', value: 'Gzip (compressed, .gz suffix)' },
      ],
    },
  },
}
