import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const IconLeft: React.FC<IconProps> = ({ scale = 20, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.1936 2.53483C6.40783 2.32097 6.40783 1.97423 6.1936 1.76037C5.97937 1.54651 5.63204 1.54651 5.41781 1.76037L1.76068 5.43334C1.64876 5.54506 1.59531 5.69246 1.60033 5.83826C1.6054 5.99753 1.6786 6.13964 1.79179 6.2365L5.41781 9.83646C5.63204 10.0503 5.97937 10.0503 6.1936 9.83646C6.40783 9.62261 6.40783 9.27587 6.1936 9.06201L3.49511 6.36817L10.9257 6.36817C11.5562 6.36817 12.1608 6.61819 12.6066 7.06322C13.0524 7.50825 13.3029 8.11184 13.3029 8.74121V10.9317C13.3029 12.2423 12.2386 13.3047 10.9257 13.3047H5.80575C5.50278 13.3047 5.25718 13.5499 5.25718 13.8524C5.25718 14.1548 5.50278 14.4 5.80575 14.4H10.9257C12.8445 14.4 14.4 12.8472 14.4 10.9317V8.74121C14.4 7.82136 14.034 6.93919 13.3824 6.28876C12.7309 5.63833 11.8472 5.27293 10.9257 5.27293L3.45077 5.27293L6.1936 2.53483Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
