import React, { useEffect, useState } from 'react'
import { Section } from 'components/common'
import { useIsMobile } from 'hooks'
import { useHistory } from 'react-router-dom'
import { DestinationCard } from 'components/chord-cdp/overview/DestinationCard'
import { StreamCard } from 'components/chord-cdp/overview/StreamCard'
import { ConnectionsDiagram } from 'components/chord-cdp/overview/ConnectionDiagram'
import { ConnectorCard } from 'components/chord-cdp/overview/ConnectorCard'
import { useCdp } from 'components/chord-cdp/provider/CdpProvider'
import { Errors } from 'components/chord-cdp/overview/Errors'
import EditDestinationForm from 'components/chord-cdp/destinations/EditDestinationForm'
import useUserRole from 'redux/hooks/useUserRole'
import { DestinationConfig, StreamConfig } from 'types/cdp'
import ConnectDestinationForm from 'components/chord-cdp/destinations/ConnectDestinationForm'
import { AlertModal } from '@chordco/component-library'
import { Trash } from '@chordco/component-library/components/icons/standardIcons/Trash'

type OverviewProps = {}

export const Overview: React.FC<OverviewProps> = () => {
  const isMobile = useIsMobile()
  const history = useHistory()

  const [destinationToEdit, setDestinationToEdit] = useState<DestinationConfig | null>(null)
  const [destinationToConnect, setDestinationToConnect] = useState<DestinationConfig | null>(null)
  const [linkToDisconnect, setLinkToDisconnect] = useState<{
    from: StreamConfig
    to: DestinationConfig
  } | null>(null)
  const [destinationToDelete, setDestinationToDelete] = useState<DestinationConfig | null>(null)

  const {
    state: { streams, destinations, connectors, links, loadingStates, errors },
    fetchStreams,
    fetchDestinations,
    fetchConnectors,
    fetchLinks,
    disconnectDestination,
    deleteDestination,
  } = useCdp()

  const fetchAllDataAsync = async () => {
    await Promise.all([fetchStreams(), fetchDestinations(), fetchConnectors(), fetchLinks()])
  }

  useEffect(() => {
    fetchAllDataAsync()
  }, [fetchStreams, fetchDestinations, fetchConnectors, fetchLinks])

  const handleStreamSelection = (stream: any) => {
    // eslint-disable-next-line no-console
    console.log(stream)
  }

  const handleConnectorSelection = (connector: any) => {
    // eslint-disable-next-line no-console
    console.log(connector)
  }

  const handleAddNewConnector = () => {
    // eslint-disable-next-line no-console
    console.log('Add new connector')
  }

  const handleAddNewSource = () => {
    // eslint-disable-next-line no-console
    console.log('Add new source')
  }

  const handleDestinationEdited = async () => {
    setDestinationToEdit(null)
    await fetchDestinations()
  }

  const handleDestinationConnected = async () => {
    setDestinationToConnect(null)
    await fetchAllDataAsync()
  }

  const handleShowDestinationCatalog = () => {
    history.push('/chord-cdp/catalog')
  }

  const handleDisconnectDestination = async (destination: DestinationConfig) => {
    // Find the link that corresponds to the destination
    const link = links.find(l => l.toId === destination.id)

    if (!link) {
      // eslint-disable-next-line no-console
      console.error(`No link found for destination ${destination.name}`)
      return
    }

    // Find the stream that corresponds to the link
    const stream = streams.find(s => s.id === link.fromId)

    if (!stream) {
      // eslint-disable-next-line no-console
      console.error(`No stream found for link ${link.id}`)
      return
    }

    setLinkToDisconnect({ from: stream, to: destination })
  }

  const handleConfirmDisconnection = async () => {
    if (!linkToDisconnect) {
      // eslint-disable-next-line no-console
      console.error('No disconnect data found')
      return
    }

    const result = await disconnectDestination(linkToDisconnect.from.id, linkToDisconnect.to.id)

    if (result === true) {
      setLinkToDisconnect(null)
      await fetchDestinations()
      await fetchLinks()
    }
  }

  const handleDeleteDestination = async (destination: DestinationConfig) => {
    setDestinationToDelete(destination)
  }

  const handleConfirmDeletion = async () => {
    if (!destinationToDelete) {
      // eslint-disable-next-line no-console
      console.error('No destination to delete')
      return
    }

    const result = await deleteDestination(destinationToDelete.id)

    if (result === true) {
      setDestinationToDelete(null)
      await fetchDestinations()
      await fetchLinks()
    }
  }

  const isFetching =
    loadingStates.streams ||
    loadingStates.destinations ||
    loadingStates.connectors ||
    loadingStates.links

  const hasFetchErrors = errors.streams || errors.destinations || errors.connectors || errors.links

  const role = useUserRole()

  // Only allow adding destinations for certain roles
  const isAdmin = role === 'admin' || role === 'superuser' || role === 'data_admin'

  // For now we are disabling the ability to add sources and connectors for anyone
  const canAddSources = false
  const canAddConnectors = false

  const renderConnectors = connectors.map(connector => ({
    id: connector.id,
    card: (forceSelect: boolean) => (
      <ConnectorCard
        selected={forceSelect}
        key={connector.id}
        connector={connector}
        onSelectConnector={handleConnectorSelection}
      />
    ),
  }))

  const renderSources = streams.map(stream => ({
    id: stream.id,
    card: (forceSelect: boolean) => (
      <StreamCard
        selected={forceSelect}
        key={stream.id}
        stream={stream}
        onSelectStream={handleStreamSelection}
      />
    ),
  }))

  const renderDestinations = destinations.map(destination => ({
    id: destination.id,
    card: (forceSelect: boolean) => (
      <DestinationCard
        selected={forceSelect}
        key={destination.id}
        destination={destination}
        onEditDestination={() => setDestinationToEdit(destination)}
        onConnectDestination={() => setDestinationToConnect(destination)}
        onDisconnectDestination={handleDisconnectDestination}
        onDeleteDestination={handleDeleteDestination}
        connected={links.some(l => l.toId === destination.id)}
        canConnectDestination={isAdmin}
        canEditDestination={isAdmin}
        canDisconnectDestination={isAdmin}
        canDeleteDestination={isAdmin}
      />
    ),
  }))

  const renderConnections = links.map(link => ({
    from: link.fromId,
    to: link.toId,
  }))

  return (
    <Section isMobile={isMobile} hasBorder={false} loading={isFetching}>
      {hasFetchErrors && <Errors errors={errors} />}

      {!isFetching && (
        <ConnectionsDiagram
          connectorSources={renderConnectors}
          sources={renderSources}
          destinations={renderDestinations}
          connections={renderConnections}
          onAddNewConnector={handleAddNewConnector}
          onAddNewDestination={handleShowDestinationCatalog}
          onAddNewSource={handleAddNewSource}
          canAddNewConnector={canAddConnectors}
          canAddNewSource={canAddSources}
          canAddNewDestination={isAdmin}
        />
      )}

      {destinationToEdit && (
        <EditDestinationForm
          destination={destinationToEdit}
          onDestinationEdited={handleDestinationEdited}
          onClose={() => setDestinationToEdit(null)}
        />
      )}

      {destinationToConnect && (
        <ConnectDestinationForm
          selectedDestination={destinationToConnect}
          streams={streams}
          destinations={destinations}
          links={links}
          onDestinationConnected={handleDestinationConnected}
          onClose={() => setDestinationToConnect(null)}
        />
      )}

      {linkToDisconnect && (
        <AlertModal
          onClose={() => setLinkToDisconnect(null)}
          icon={Trash}
          content={`Are you sure you want to disconnect ${linkToDisconnect.to.name} from ${linkToDisconnect.from.name}?`}
          centered
          buttons={[
            {
              id: 1,
              text: 'No',
              onClick: () => setLinkToDisconnect(null),
              purpose: 'secondary',
              disabled: loadingStates.disconnectDestination,
            },
            {
              id: 2,
              text: 'Yes, disconnect it',
              onClick: () => handleConfirmDisconnection(),
              purpose: 'primary',
              isLoading: loadingStates.disconnectDestination,
              disabled: loadingStates.disconnectDestination,
            },
          ]}
        />
      )}

      {destinationToDelete && (
        <AlertModal
          onClose={() => setDestinationToDelete(null)}
          icon={Trash}
          content={`Are you sure you want to delete ${destinationToDelete.name}?`}
          centered
          buttons={[
            {
              id: 1,
              text: 'No',
              onClick: () => setDestinationToDelete(null),
              purpose: 'secondary',
              disabled: loadingStates.deleteDestination,
            },
            {
              id: 2,
              text: 'Yes, delete it',
              onClick: () => handleConfirmDeletion(),
              purpose: 'primary',
              isLoading: loadingStates.deleteDestination,
              disabled: loadingStates.deleteDestination,
            },
          ]}
        />
      )}
    </Section>
  )
}
