import { createSlice } from '@reduxjs/toolkit'
import { useStateSliceAndDispatch } from '../utils'
import { DataSettingsState } from 'types'
import { useTenantsData } from 'redux/state/tenants'
import { reduxApiClient } from 'redux/api'

const SLICE_NAME = 'dataSettings'
const initialState: DataSettingsState = {
  dataSettings: {
    id: 1,
    startOfWeek: {
      id: 1,
      label: 'Sunday',
    },
    startOfYear: {
      id: 1,
      label: 'January',
    },
    timezone: 'America - New York',
    fiscalPeriod: '4-4-5',
    customDates: null,
  },
  timezones: {
    united_states_timezones: {},
  },
}

const { getDataSettings, getDataTimezones } = reduxApiClient

const dataSettingsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetDataSettings: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getDataSettings.fulfilled, (state, action) => {
      state.dataSettings = action.payload.data
    })

    builder.addCase(getDataTimezones.fulfilled, (state, action) => {
      state.timezones = action.payload.data
    })
  },
})

export default dataSettingsSlice.reducer

export const useDataSettingsData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)
  const {
    state: { currentTenant },
  } = useTenantsData()

  const dataSettings = state.dataSettings
  const timezones = state.timezones
  const tenantId = currentTenant?.id

  if (!tenantId) {
    return {
      dataSettings,
      getDataSettings: async () => {
        throw new Error('tenantIdis undefined')
      },
      timezones,
      getDataTimezones: async () => {
        throw new Error('tenantId is undefined')
      },
    }
  }

  return {
    dataSettings,
    getDataSettings: () => {
      dispatch(getDataSettings(tenantId))
    },
    timezones,
    getDataTimezones: () => {
      dispatch(getDataTimezones(tenantId))
    },
  }
}
