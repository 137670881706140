import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useTenantsData } from 'redux/state/tenants'
import { useEnvironmentsData } from 'redux/state/environments'
import { CdpProvider } from 'components/chord-cdp/provider/CdpProvider'
import { DestinationCatalog } from 'components/chord-cdp/catalogs/DestinationCatalog'
import { ChordCdp } from 'components/chord-cdp'

const CdpRoutes: React.FC = () => {
  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()

  if (!currentTenant) throw new Error('No current tenant')
  if (!currentStore) throw new Error('currentStore is undefined')
  if (!selectedEnvironment) throw new Error('No selected environment')

  return (
    <CdpProvider
      tenantId={currentTenant.id}
      storeId={currentStore.id}
      environment={selectedEnvironment}
    >
      <Switch>
        <Route exact path="/chord-cdp" component={ChordCdp} />
        <Route exact path="/chord-cdp/catalog" component={DestinationCatalog} />
      </Switch>
    </CdpProvider>
  )
}

export default CdpRoutes
