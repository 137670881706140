import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Button, standardIcons } from '@chordco/component-library'
import { UserDashboardEmbed } from './UserDashboardEmbed'
import { UserLookEmbed } from './UserLookEmbed'
import { AvailableUserReport } from 'types'
import { SelectChangeEvent } from '@mui/material'
import { ReportFolderDropdown } from './ReportFolderDropdown'
import { LookEvent } from '@looker/embed-sdk'
import { usePinReport } from './usePinReport'
import { useUnpinReport } from './useUnpinReport'
import useCanPinReport from 'redux/hooks/useCanPinReport'
import usePinnedReport from 'redux/hooks/usePinnedReport'
import EmptySharedReports from 'components/analytics/reports/EmptySharedReports'

type SharedReportProps = {
  sharedReports: AvailableUserReport[]
  onDeletedDashboard: () => void
  onDeletedLook: (event: LookEvent) => void
  onSavedLook: (report: AvailableUserReport) => void
}

export const SharedReports: React.FC<SharedReportProps> = ({
  sharedReports,
  onDeletedDashboard,
  onDeletedLook,
  onSavedLook,
}) => {
  if (sharedReports.length === 0) return <EmptySharedReports />

  const history = useHistory()
  const slug = history.location.pathname.split('/').pop()

  const selectedOption = sharedReports.find(d => d.slug === slug) || sharedReports[0]

  if (!selectedOption) return null

  const canPin = useCanPinReport()

  const { pin, isLoading: isPinning } = usePinReport()
  const { unpin, isLoading: isUnpinning } = useUnpinReport()

  const redirectToReport = (report: AvailableUserReport) => {
    history.push(`/analytics/shared-reports/${report.slug}`)
  }

  const handleSelectChanged = (event: SelectChangeEvent<string>) => {
    const report = sharedReports.find(d => d.id === event.target.value)
    if (report) redirectToReport(report)
  }

  const handlePinReport = async () => {
    await pin({
      reportId: selectedOption.id,
      reportType: selectedOption.metricType,
    })
  }

  const handleUnpinReport = async () => {
    await unpin({
      slug: selectedOption.slug,
      reportType: selectedOption.metricType,
    })
  }

  const handleDeletedDashboard = async () => {
    if (isPinned) {
      await unpin({
        slug: selectedOption.slug,
        reportType: selectedOption.metricType,
      })
    }

    onDeletedDashboard()
  }

  const handleDeletedLook = async (event: LookEvent) => {
    if (isPinned) {
      await unpin({
        slug: selectedOption.slug,
        reportType: selectedOption.metricType,
      })
    }

    onDeletedLook(event)
  }

  const handleSavedLook = async () => {
    onSavedLook(selectedOption)
  }

  const { isPinned } = usePinnedReport(selectedOption.slug)

  return (
    <Wrapper>
      <Top>
        <ReportFolderDropdown
          title="Shared Reports"
          dashboards={sharedReports.filter(d => d.metricType === 'dashboard')}
          looks={sharedReports.filter(d => d.metricType === 'look')}
          onChange={handleSelectChanged}
          defaultValue={selectedOption.id}
        />

        <RightButtons>
          {isPinned ? (
            <Button
              purpose="tertiary"
              icon={standardIcons.Unpin}
              onClick={handleUnpinReport}
              isLoading={isUnpinning}
              disabled={!canPin}
              tooltip={!canPin ? 'Please contact an admin to unpin this report' : ''}
              tooltipDirection="input-bottom-right"
            >
              Unpin
            </Button>
          ) : (
            <Button
              purpose="tertiary"
              icon={standardIcons.Pin}
              onClick={handlePinReport}
              isLoading={isPinning}
              disabled={!canPin}
              tooltip={!canPin ? 'Please contact an admin to pin this report' : ''}
              tooltipDirection="input-bottom-right"
            >
              Pin
            </Button>
          )}
        </RightButtons>
      </Top>
      <EmbedContainer>
        {selectedOption.metricType === 'dashboard' && (
          <UserDashboardEmbed dashboardId={selectedOption.slug} onDeletedDashboard={handleDeletedDashboard} />
        )}

        {selectedOption.metricType === 'look' && (
          <UserLookEmbed lookId={selectedOption.slug} onDeletedLook={handleDeletedLook} onSavedLook={handleSavedLook} />
        )}
      </EmbedContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
`
