import React from 'react'
import { createId } from '@paralleldrive/cuid2'
import { Box, Switch, TextField, Typography } from '@mui/material'
import { renderFormComponent, transformFormData } from 'components/chord-cdp/utils/forms'
import { DestinationType } from 'components/chord-cdp/destinations'
import { useCdp } from 'components/chord-cdp/provider/CdpProvider'
import { Error } from 'components/chord-cdp/overview/Error'
import { FormSheet } from 'components/common'
import { Button } from '@chordco/component-library'
import { Check as CheckIcon, Error as ErrorIcon } from '@mui/icons-material'

type AddDestinationFormProps = {
  destinationType: DestinationType
  onDestinationAdded: () => void
  onClose: () => void
}

const AddDestinationForm: React.FC<AddDestinationFormProps> = ({
  destinationType,
  onDestinationAdded,
  onClose,
}) => {
  const {
    state: { loadingStates, errors },
    addDestination,
    testDestination,
    clearError,
  } = useCdp()

  const [testStatus, setTestStatus] = React.useState<string | null>(null)

  const { id, credentials } = destinationType

  if (!credentials) {
    // eslint-disable-next-line no-console
    console.error(`Could not find credentials for destination type ${id}`)
    return null
  }

  // Initialize formData from credentials default values
  const [formData, setFormData] = React.useState<Record<string, any>>(() => {
    const defaultFormData = credentials
      ? Object.fromEntries(
          Object.entries(credentials).map(([key, config]) => [
            key,
            config.renderComponent === Switch
              ? config.componentProps?.defaultChecked ?? false
              : config.componentProps?.defaultValue || '',
          ])
        )
      : {}

    return {
      ...defaultFormData,
      id: createId(),
      type: 'destination',
      destinationType: id,
    }
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = transformFormData(formData, credentials)
    const result = await addDestination(data)

    if (result === true) onDestinationAdded()
  }

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleClose = () => {
    clearError('addDestination')
    clearError('testDestination')
    onClose()
  }

  const handleTestConnection = async () => {
    const data = transformFormData(formData, credentials)
    const result = await testDestination(data)
    setTestStatus(result === true ? 'success' : 'error')
  }

  const handleCloseAddError = () => {
    clearError('addDestination')
  }

  const handleCloseTestError = () => {
    clearError('testDestination')
    setTestStatus(null)
  }

  return (
    <FormSheet
      onSubmit={handleSubmit}
      onClose={handleClose}
      customTitle={`Create ${destinationType.title} Destination`}
      isEdit={false}
      isLarge={true}
      isLoading={loadingStates.addDestination}
      submitDisabled={loadingStates.addDestination}
      name="Add Destination"
      location="Chord CDP"
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
          <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
            Destination Name
          </Typography>
          <TextField
            label="Destination Name"
            onChange={e => handleChange('name', e.target.value)}
            required
            variant="outlined"
          />
        </Box>

        {renderFormComponent(destinationType, formData, handleChange)}

        {destinationType.usesBulker && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleTestConnection}
            name="Test Edit Connection"
            location="Chord CDP"
            style={{ alignSelf: 'flex-end' }}
            isLoading={loadingStates.testDestination}
            disabled={loadingStates.testDestination}
            icon={testStatus === 'success' ? CheckIcon : testStatus === 'error' ? ErrorIcon : null}
          >
            {testStatus === 'success'
              ? 'Connection Successful'
              : testStatus === 'error'
              ? 'Connection Failed'
              : 'Test Connection'}
          </Button>
        )}

        {errors.addDestination && (
          <Error error={errors.addDestination} onClose={handleCloseAddError} />
        )}

        {errors.testDestination && (
          <Error error={errors.testDestination} onClose={handleCloseTestError} />
        )}
      </Box>
    </FormSheet>
  )
}

export default AddDestinationForm
