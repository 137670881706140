import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { reduxApiClient } from 'redux/api'
import { RootState } from 'redux/store'
import {
  getCurrentTenant,
  setCurrentTenant,
} from 'redux/utils/localStorageHelpers'
import { CreateTenantRequest, TenantsState } from 'types'
import { useStateSliceAndDispatch } from '../utils'
import {
  TenantConfigurationServiceRequest,
  TenantModelAmplificationRequest,
} from 'utils/notion/service'

const SLICE_NAME = 'tenants'

const initialState: TenantsState = {
  tenants: [],
  currentStoreIsAutonomy: false,
}

export interface SelectTenantPayload {
  tenantId: number
}

const {
  getUserTenants,
  createTenant,
  createTenantConfigurationServiceRequest,
  createTenantModelAmplificationRequest,
} = reduxApiClient

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    selectTenant: (state, action: PayloadAction<SelectTenantPayload>) => {
      const newTenant = state.tenants.find(
        tenant => tenant.id === action.payload.tenantId
      )

      if (!newTenant) return

      state.currentTenant = newTenant
      const newStore = newTenant.stores.find(s => s.environments.length)
      state.currentStore = newStore
      state.currentStoreIsAutonomy = newStore?.plan === 'autonomy'

      setCurrentTenant(newTenant)
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserTenants.fulfilled, (state, action) => {
      const tenants = action.payload.data
      if (!tenants) return

      const currentTenant = getCurrentTenant(tenants)
      const currentStore = currentTenant.stores.find(s => s.environments.length)

      state.tenants = tenants
      state.currentTenant = currentTenant
      state.currentStore = currentStore
      state.currentStoreIsAutonomy = currentStore?.plan === 'autonomy'
    })
  },
})

export default tenantsSlice.reducer

export const useTenantsData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const solidusInfo = useSelector(
    (state: RootState) => state.stores.solidusInfo
  )

  const setNewEnvironments = (tenantId: number) => {
    const newTenant = state.tenants.find(tenant => tenant.id === tenantId)

    if (!newTenant) return

    const newStore = newTenant.stores.find(s => s.environments.length)
    const environments = newStore?.environments

    if (!environments) return

    const environment =
      environments.find(p => p === 'production') || environments[0]
    const solidusSettings = solidusInfo[environment]
    const settings = {
      baseUrl: solidusSettings?.apiHost || '',
      apiKey: solidusSettings?.apiKey || '',
      storeId: solidusSettings?.solidusStoreId || 0,
    }

    dispatch({
      type: 'environments/selectEnvironment',
      payload: { settings, environment, environments },
    })
  }

  return {
    state,
    getUserTenants: () => dispatch(getUserTenants()),
    selectTenant: (tenantId: number) => {
      if (tenantId === state.currentTenant?.id) return

      dispatch({ type: 'tenants/selectTenant', payload: { tenantId } })
      dispatch({ type: 'businessErrors/resetBusinessErrors' })
      dispatch({ type: 'configSheets/resetConfigSheets' })
      dispatch({ type: 'environments/resetEnvironments' })
      dispatch({ type: 'looker/resetLooker' })
      dispatch({ type: 'notifications/resetNotifications' })
      dispatch({ type: 'quickstartsFilter/resetFilters' })
      dispatch({ type: 'reportsFilter/resetFilters' })
      dispatch({ type: 'rolePermissionDescriptions/resetRolePermissionDescriptions' })
      dispatch({ type: 'store/resetStore' })
      dispatch({ type: 'storeSetup/resetStoreSetup' })
      dispatch({ type: 'systemHealth/resetSystemHealth' })
      dispatch({ type: 'userNotifications/resetUserNotifications' })
      dispatch({ type: 'users/resetUsers' })
      dispatch({ type: 'dataSettings/resetDataSettings' })

      setNewEnvironments(tenantId)
    },
    createTenant: (data: CreateTenantRequest) =>
      dispatch(createTenant(data)).then(() => {
        dispatch(getUserTenants())
      }),
    createTenantConfigurationServiceRequest: (
      data: TenantConfigurationServiceRequest
    ) =>
      dispatch(createTenantConfigurationServiceRequest(data)).then(() => {
        dispatch(getUserTenants())
      }),
    createModelAmplificationRequest: (data: TenantModelAmplificationRequest) =>
      dispatch(createTenantModelAmplificationRequest(data)).then(() => {
        dispatch(getUserTenants())
      }),
  }
}
