import React, { useMemo } from 'react'
import { Button, Label, standardIcons } from '@chordco/component-library'
import { FormControl, Select, SelectChangeEvent, MenuItem, Box } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { ConfigurationObjectLink, DestinationConfig, ServiceConfig, StreamConfig } from 'types/cdp'
import { mapLinksToConnections } from 'components/chord-cdp/events/utils'
import { DestinationEventDropdownItem } from 'components/chord-cdp/events/destinations/DestinationEventDropdownItem'

type EventToolbarProps = {
  links: ConfigurationObjectLink[]
  streams: StreamConfig[]
  connectors: ServiceConfig[]
  destinations: DestinationConfig[]
  filters: DestinationEventFilters
  onFiltersChange: (filters: DestinationEventFilters) => void
  onRefresh: (filters: DestinationEventFilters) => void
}

type EventStatusLevel = 'all' | 'errors'
type EventMode = 'batch' | 'stream'

export type DestinationEventFilters = {
  connection?: ConfigurationObjectLink
  statusLevel: EventStatusLevel
  mode: EventMode
  dateFrom: string
  dateTo: string
}

export const DestinationEventsToolbar: React.FC<EventToolbarProps> = ({
  links,
  streams,
  connectors,
  destinations,
  filters,
  onFiltersChange,
  onRefresh,
}) => {
  const options = useMemo(
    () => mapLinksToConnections(streams, connectors, destinations, links),
    [streams, destinations, links]
  )

  const handleConnectionChange = (event: SelectChangeEvent<string>) => {
    const updatedConnection = links.find(link => link.id === event.target.value) || links[0]
    const updatedFilters = { ...filters, connection: updatedConnection }
    onFiltersChange(updatedFilters)
  }

  const handleStatusChange = (statusLevel: EventStatusLevel) => {
    const updatedFilters = { ...filters, statusLevel }
    onFiltersChange(updatedFilters)
  }

  const handleModeChange = (mode: EventMode) => {
    const updatedFilters = { ...filters, mode }
    onFiltersChange(updatedFilters)
  }

  const handleDateChange = (key: 'dateFrom' | 'dateTo') => (newValue: Moment | null) => {
    const updatedFilters = {
      ...filters,
      [key]: newValue ? moment.parseZone(newValue).utcOffset(0, true).toISOString() : '',
    }
    onFiltersChange(updatedFilters)
  }

  const handleRefresh = () => {
    onRefresh(filters)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        paddingTop: 1,
        paddingBottom: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <FormControl sx={{ minWidth: 100 }} size="small">
          <Label>Connection</Label>
          <Select value={filters.connection?.id || ''} onChange={handleConnectionChange}>
            {options.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <DestinationEventDropdownItem item={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 90 }} size="small">
          <Label>Status</Label>
          <Select
            value={filters.statusLevel}
            onChange={(event: SelectChangeEvent<string>) =>
              handleStatusChange(event.target.value as EventStatusLevel)
            }
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="errors">Errors</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 90 }} size="small">
          <Label>Mode</Label>
          <Select
            value={filters.mode}
            onChange={(event: SelectChangeEvent<string>) =>
              handleModeChange(event.target.value as EventMode)
            }
          >
            <MenuItem value="batch">Batch</MenuItem>
            <MenuItem value="stream">Stream</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 100 }} size="small">
          <Label>Start Date</Label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              value={filters.dateFrom ? moment.parseZone(filters.dateFrom).utc() : null}
              onAccept={handleDateChange('dateFrom')}
              closeOnSelect={false}
              format="MMM DD, HH:mm"
              slotProps={{
                textField: { size: 'small' },
                actionBar: {
                  actions: ['clear', 'cancel', 'accept'],
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ minWidth: 100 }} size="small">
          <Label>End Date</Label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              value={filters.dateTo ? moment.parseZone(filters.dateTo).utc() : null}
              onAccept={handleDateChange('dateTo')}
              closeOnSelect={false}
              format="MMM DD, HH:mm"
              slotProps={{
                textField: { size: 'small' },
                actionBar: {
                  actions: ['clear', 'cancel', 'accept'],
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <Button
          type="button"
          purpose="secondary"
          onClick={handleRefresh}
          icon={standardIcons.Refresh}
          name="Refresh"
          location="CDP Destination Events"
        >
          Refresh
        </Button>
      </Box>
    </Box>
  )
}
