import React from 'react'
import { TextField, Switch } from '@mui/material'
import PasswordField from 'components/chord-cdp/common/PasswordField'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const mixpanelCredentialsConfig: CredentialsConfig = {
  simplifiedIdMerge: {
    description: `Use Mixpanel <a href="https://docs.mixpanel.com/docs/tracking-methods/identifying-users">Simplified Identity Merge</a> feature.<br/>Enable this option if your Mixpanel project has the corresponding <a href="https://docs.mixpanel.com/docs/tracking-methods/identifying-users#how-do-i-switch-between-the-simplified-and-original-api">feature enabled</a>.<br/><b>Using this feature is highly recommended to achieve better quality Identity Merge</b>`,
    renderComponent: Switch,
    componentProps: {
      label: 'Simplified Identity Merge',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  projectId: {
    description:
      'Project ID can be found in the <a href="https://help.mixpanel.com/hc/en-us/articles/115004490503-Project-Settings">project settings</a>',
    renderComponent: TextField,
    componentProps: {
      label: 'Project ID',
      type: 'text',
      required: true,
    },
  },
  projectToken: {
    description:
      'See <a href="https://developer.mixpanel.com/reference/project-token">how to obtain project secret</a>',
    renderComponent: TextField,
    componentProps: {
      label: 'Project Token',
      type: 'password',
      required: true,
    },
  },
  serviceAccountUserName: {
    description:
      'See <a href="https://developer.mixpanel.com/reference/service-accounts">how to create service account</a>',
    renderComponent: TextField,
    componentProps: {
      label: 'Service Account Username',
      type: 'text',
      required: true,
    },
  },
  serviceAccountPassword: {
    description:
      'See <a href="https://developer.mixpanel.com/reference/service-accounts">how to create service account</a>',
    renderComponent: PasswordField,
    componentProps: {
      label: 'Service Account Password',
      type: 'password',
      required: true,
    },
  },
  sendPageEvents: {
    description: 'If enabled, all page view events will be sent to Mixpanel.',
    renderComponent: Switch,
    componentProps: {
      label: 'Send Page Events',
      type: 'checkbox',
      defaultChecked: true,
    },
  },
  sendIdentifyEvents: {
    description:
      'If enabled, any identify() call will send an Identify event to Mixpanel in addition to the profile update.',
    renderComponent: Switch,
    componentProps: {
      label: 'Send Identify Events',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  enableGroupAnalytics: {
    description:
      "Mixpanel Group Analytics allows behavioral data analysis at a customized group level. Group Analytics is available as an add-on package to customers on <a href='https://mixpanel.com/pricing/' target='_blank' rel='noreferrer noopener'>Growth and Enterprise plans.</a>",
    renderComponent: Switch,
    componentProps: {
      label: 'Enable Group Analytics',
      type: 'checkbox',
    },
  },
  filterBotTraffic: {
    description: "Don't send traffic from known bots to Mixpanel",
    renderComponent: Switch,
    componentProps: {
      label: 'Filter Bot Traffic',
      type: 'checkbox',
      defaultChecked: true,
    },
  },
  groupKey: {
    description:
      "Group Key for Mixpanel Group Analytics. Make sure that Group Key in <a href='https://mixpanel.com/report' target='_blank' rel='noreferrer noopener'>Mixpanel project settings</a> matches the provided value.",
    renderComponent: TextField,
    componentProps: {
      label: 'Group Key',
      type: 'text',
      placeholder: 'e.g., $group_id',
      defaultValue: '$group_id',
    },
  },
  enableAnonymousUserProfiles: {
    description: 'If enabled, anonymous users will be tracked in Mixpanel',
    renderComponent: Switch,
    componentProps: {
      label: 'Enable Anonymous User Profiles',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
}

export const MixpanelCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={mixpanelCredentialsConfig} />
)
