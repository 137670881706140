import React from 'react'
import { TextField, Select } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import KeyValueList from 'components/chord-cdp/common/KeyValueList'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const clickhouseConnectionConfig: CredentialsConfig = {
  protocol: {
    description:
      'Protocol used for ClickHouse connection: <code>http</code>, <code>https</code>, <code>clickhouse</code>, <code>clickhouse-secure</code>',
    renderComponent: Select,
    componentProps: {
      label: 'Protocol',
      defaultValue: 'clickhouse-secure',
      fullWidth: true,
      required: true,
      options: [
        { id: 'http', value: 'http' },
        { id: 'https', value: 'https' },
        { id: 'clickhouse', value: 'clickhouse' },
        { id: 'clickhouse-secure', value: 'clickhouse-secure' },
      ],
    },
  },
  hosts: {
    description:
      'List of clickhouse servers as <code>host:port</code>. One on each line. If port is not specified, default port for respective protocol will be used: <code>http->8123</code>, <code>https->8443</code>, <code>clickhouse->9000</code>, <code>clickhouse-secure->9440</code>.',
    renderComponent: TextField,
    componentProps: {
      label: 'Hosts',
      type: 'text',
      placeholder: 'host:port',
      required: true,
      multiline: true,
      rows: 4,
    },
  },
  username: {
    description: 'Username for ClickHouse connection',
    renderComponent: TextField,
    componentProps: {
      label: 'Username',
      type: 'text',
      required: true,
    },
  },
  password: {
    description: 'Password for ClickHouse connection',
    renderComponent: TextField,
    componentProps: {
      label: 'Password',
      type: 'password',
      required: true,
    },
  },
  cluster: {
    description:
      'Name of cluster to use. If ClickHouse works in single node mode, leave this field empty',
    renderComponent: TextField,
    componentProps: {
      label: 'Cluster',
      type: 'text',
    },
  },
  database: {
    description: 'Name of the database to use',
    renderComponent: TextField,
    componentProps: {
      label: 'Database',
      type: 'text',
      defaultValue: 'default',
    },
  },
  parameters: {
    description:
      "Additional parameters for ClickHouse driver. See <a href='https://clickhouse.com/docs/en/integrations/go#connection-settings-1' rel='noreferrer noopener' target='_blank'>Clickhouse documentation</a>",
    renderComponent: KeyValueList,
    componentProps: {
      label: 'Parameters',
    },
  },
}

export const ClickHouseCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={clickhouseConnectionConfig} />
)


