import React from 'react'
import { useTheme } from '@chordco/component-library'
import { IconProps } from '../types'

export const Construction: React.FC<IconProps> = ({ scale = 76, fill, ...props }) => {
  const theme = useTheme()

  return (
    <svg
      {...props}
      width={scale}
      height={scale}
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.1449 74.2968L44.7886 51.9405L53.3636 43.3655L75.7199 65.7218L67.1449 74.2968ZM10.7949 74.2968L2.21989 65.7218L30.3949 37.5468L23.4532 30.6051L20.5949 33.4635L15.3886 28.2572V36.628L12.5303 39.4864L0.178223 27.1343L3.03656 24.276H11.4074L6.30322 19.1718L20.7991 4.67595C22.1602 3.31484 23.6234 2.32804 25.1886 1.71554C26.7539 1.10304 28.3532 0.796785 29.9866 0.796785C31.6199 0.796785 33.2192 1.10304 34.7845 1.71554C36.3498 2.32804 37.8129 3.31484 39.1741 4.67595L29.7824 14.0676L34.8866 19.1718L32.0282 22.0301L38.9699 28.9718L48.1574 19.7843C47.8852 19.0357 47.664 18.253 47.4939 17.4364C47.3237 16.6197 47.2386 15.803 47.2386 14.9864C47.2386 10.9711 48.6168 7.58533 51.373 4.82908C54.1293 2.07283 57.515 0.694702 61.5303 0.694702C62.5511 0.694702 63.5209 0.796785 64.4397 1.00095C65.3584 1.20512 66.2942 1.51137 67.247 1.9197L57.1407 12.026L64.4907 19.376L74.597 9.2697C75.0734 10.2225 75.3966 11.1582 75.5668 12.077C75.7369 12.9957 75.822 13.9655 75.822 14.9864C75.822 19.0016 74.4439 22.3874 71.6876 25.1437C68.9314 27.8999 65.5456 29.278 61.5303 29.278C60.7136 29.278 59.897 29.21 59.0803 29.0739C58.2636 28.9378 57.481 28.6996 56.7324 28.3593L10.7949 74.2968Z"
        fill={fill || theme.ContentPrimary}
      />
    </svg>
  )
}
