import React from 'react'
import { TextField, Select } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const postgresCredentialsConfig: CredentialsConfig = {
  host: {
    description: 'Postgres host',
    renderComponent: TextField,
    componentProps: {
      label: 'Host',
      type: 'text',
      required: true,
    },
  },
  port: {
    description: 'Postgres port',
    renderComponent: TextField,
    componentProps: {
      label: 'Port',
      type: 'number',
      required: true,
      defaultValue: 5432,
    },
  },
  sslMode: {
    description: 'SSL mode for Postgres connection: <code>disable</code> or <code>require</code>',
    renderComponent: Select,
    componentProps: {
      label: 'SSL Mode',
      defaultValue: 'require',
      fullWidth: true,
      required: true,
      options: [
        { id: 'disable', value: 'disable' },
        { id: 'require', value: 'require' },
      ],
    },
  },
  database: {
    description: 'Postgres database name',
    renderComponent: TextField,
    componentProps: {
      label: 'Database',
      type: 'text',
      required: true,
    },
  },
  username: {
    description: 'Postgres username',
    renderComponent: TextField,
    componentProps: {
      label: 'Username',
      type: 'text',
      required: true,
    },
  },
  password: {
    description: 'Postgres password',
    renderComponent: TextField,
    componentProps: {
      label: 'Password',
      type: 'password',
      required: true,
    },
  },
  defaultSchema: {
    description: 'Postgres schema',
    renderComponent: TextField,
    componentProps: {
      label: 'Schema',
      type: 'text',
      defaultValue: 'public',
    },
  },
}

export const PostgresCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={postgresCredentialsConfig} />
)
