import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { standardIcons, AlertModal, Button } from '@chordco/component-library'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Loading } from 'components/common'
import { ExploreEmbed } from './ExploreEmbed'
import useEmbedUrl from './useEmbedUrl'
import { AvailableReport } from 'types'

type Props = {
  options: AvailableReport[]
}

const getLinkFromSlug = (slug: string) => `/analytics/explores/${slug}`

const { AlertInfo } = standardIcons

type ModalProps = {
  onClose: () => void
  title: string
  content: string
  link?: {
    text: string
    href: string
  }
}

const ExploresModal: React.FC<ModalProps> = ({ onClose, title, content, link }) => (
  <AlertModal
    onClose={onClose}
    title={title}
    content={content}
    link={link}
    icon={AlertInfo}
    width="482px"
    buttons={[
      {
        id: 1,
        text: 'Close',
        onClick: onClose,
        purpose: 'primary',
      },
    ]}
    centered
  />
)

export const Explore: React.FC<Props> = ({ options }) => {
  const dropdownOptions = options.map(o => ({
    id: o.slug,
    label: o.label,
  }))

  const history = useHistory()
  const slug = history.location.pathname.split('/').pop()

  const selectedOption = options.find(o => o.slug === slug) || options[0]

  if (!selectedOption) return null

  const [showInfoModal, setShowInfoModal] = useState(false)

  const { embedUrls, isLoading } = useEmbedUrl(selectedOption.slug, 'explore')

  return (
    <Wrapper>
      <Top>
        <FormControl sx={{ minWidth: 300 }} size="small">
          <Select
            value={selectedOption.slug}
            onChange={(event: SelectChangeEvent<string>) => {
              history.push(getLinkFromSlug(event.target.value))
            }}
          >
            {dropdownOptions.length > 0 &&
              dropdownOptions.map(explore => (
                <MenuItem key={explore.id} value={explore.id}>
                  {explore.label}
                </MenuItem>
              ))}

            {dropdownOptions.length === 0 && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
          </Select>
        </FormControl>

        <Right>
          <Button size="small" purpose="tertiary" onClick={() => setShowInfoModal(true)}>
            What are Explores?
          </Button>
        </Right>
      </Top>
      <EmbedContainer>{isLoading ? <Loading /> : <ExploreEmbed url={embedUrls[selectedOption.slug]} />}</EmbedContainer>

      {showInfoModal && (
        <ExploresModal
          onClose={() => setShowInfoModal(false)}
          title="What are Explores?"
          content="Explores help you navigate data tables. Each one is a collection of measures and dimensions that can be dragged and dropped as columns, pivots, or filters to create custom reports and visualizations."
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
`
