import React, { useEffect, useMemo, useState } from 'react'
import { Section } from 'components/common'
import { useIsMobile } from 'hooks'
import { RawDestinationEvent } from 'types/cdp'
import { useCdp } from 'components/chord-cdp/provider/CdpProvider'
import {
  DestinationEventFilters,
  DestinationEventsToolbar,
} from 'components/chord-cdp/events/destinations/DestinationEventsToolbar'
import { DestinationEventDrawer } from 'components/chord-cdp/events/destinations/DestinationEventDrawer'
import { DestinationEventsTable } from 'components/chord-cdp/events/destinations/DestinationEventsTable'
import { parseDestinationEventRows } from 'components/chord-cdp/events/utils'

export interface ParsedDestinationEventRow {
  date: string
  level: string
  batchSize: number
  status: string
  tableName: string
  summary: any
  rawEvent?: RawDestinationEvent
}

export const DestinationEvents: React.FC = () => {
  const isMobile = useIsMobile()

  const {
    state: { streams, connectors, destinations, destinationEvents, links, loadingStates },
    fetchStreams,
    fetchConnectors,
    fetchDestinations,
    fetchDestinationEvents,
    fetchLinks,
  } = useCdp()

  const [filters, setFilters] = useState<DestinationEventFilters>(() => ({
    connection: links[0],
    statusLevel: 'all',
    mode: 'batch',
    dateFrom: '',
    dateTo: '',
  }))

  const [selectedRow, setSelectedRow] = useState<ParsedDestinationEventRow | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchStreams(), fetchDestinations(), fetchConnectors(), fetchLinks()])
    }

    fetchData()
  }, [fetchStreams, fetchDestinations, fetchConnectors, fetchLinks])

  useEffect(() => {
    const refetchDestinationEvents = async () => {
      if (filters.connection?.id) {
        await fetchDestinationEvents(filters.connection.id, filters)
      }
    }

    refetchDestinationEvents()
  }, [filters, fetchDestinationEvents])

  const parsedRows = useMemo(
    () => parseDestinationEventRows(destinationEvents),
    [destinationEvents]
  )

  const handleFiltersChange = (updatedFilters: DestinationEventFilters) => {
    setFilters(updatedFilters)
  }

  const handleRefresh = () => {
    if (filters.connection?.id) {
      fetchDestinationEvents(filters.connection.id, filters)
    } else {
      fetchLinks()
    }
  }

  const handleRowClick = (row: ParsedDestinationEventRow) => {
    setSelectedRow(row)
  }

  const isLoading =
    loadingStates.streams ||
    loadingStates.destinations ||
    loadingStates.connectors ||
    loadingStates.links ||
    loadingStates.destinationEvents

  return (
    <Section isMobile={isMobile} hasBorder={false} loading={isLoading} title="Destination Events">
      {!isLoading && (
        <>
          <DestinationEventsToolbar
            links={links}
            streams={streams}
            destinations={destinations}
            connectors={connectors}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            onRefresh={handleRefresh}
          />
          <DestinationEventsTable rows={parsedRows} onRowClick={handleRowClick} />
        </>
      )}

      {selectedRow && selectedRow && (
        <DestinationEventDrawer
          event={selectedRow}
          onClose={() => setSelectedRow(null)}
          mappedDestinations={links}
          destinations={destinations}
        />
      )}
    </Section>
  )
}
