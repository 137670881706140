import React from 'react'
import {
  Box,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import KeyValueList from 'components/chord-cdp/common/KeyValueList'
import SnippetEditor from 'components/chord-cdp/common/SnippetEditor'
import PasswordField from 'components/chord-cdp/common/PasswordField'
import { FormFieldConfig } from 'components/chord-cdp/configs'

type FormComponentProps = {
  values: Record<string, any>
  onChange: (field: string, value: any) => void
  config: Record<string, FormFieldConfig> // Configuration object, e.g., `redshiftCredentialsConfig` or `clickhouseConnectionConfig`
  getVisibilityRules?: (values: Record<string, any>) => Record<string, boolean> // Optional visibility rules
}

type RenderFieldProps = {
  fieldConfig: FormFieldConfig
  field: string
  values: Record<string, any>
  onChange: (field: string, value: any) => void
}

const GenericCredentialsForm: React.FC<FormComponentProps> = ({
  values,
  onChange,
  config,
  getVisibilityRules,
}) => {
  const visibilityRules = React.useMemo(() => {
    return getVisibilityRules ? getVisibilityRules(values) : null
  }, [values])

  const renderField = ({
    fieldConfig,
    field,
    values,
    onChange,
  }: RenderFieldProps): React.ReactNode => {
    const { renderComponent: RenderComponent, componentProps = {} } = fieldConfig

    // Extract the defaultValue from componentProps since we are using controlled components
    const { defaultValue = '', defaultChecked = false, ...restProps } = componentProps || {}

    if (RenderComponent === KeyValueList) {
      return (
        <KeyValueList
          type={field}
          state={values[field] || []}
          setState={(newState: any) => onChange(field, newState)}
        />
      )
    }

    if (RenderComponent === SnippetEditor) {
      return (
        <SnippetEditor
          {...restProps}
          languages={['html', 'javascript']}
          value={values[field] || defaultValue || ''}
          onChange={(value: any) => onChange(field, value)}
        />
      )
    }

    if (RenderComponent === Switch) {
      return (
        <Switch
          {...restProps}
          checked={values[field] || defaultChecked || false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(field, e.target.checked)}
        />
      )
    }

    if (
      (RenderComponent === TextField && !componentProps?.multiline) ||
      RenderComponent === PasswordField
    ) {
      return (
        <TextField
          {...restProps}
          value={values[field] || defaultValue || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(field, e.target.value)}
        />
      )
    }

    if (RenderComponent === TextField && componentProps?.multiline) {
      return (
        <TextField
          {...restProps}
          value={
            (Array.isArray(values[field]) ? values[field].join('\n') : values[field]) ||
            defaultValue ||
            ''
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(field, e.target.value)}
        />
      )
    }

    if (RenderComponent === Select) {
      const options = componentProps?.options || []
      return (
        <Select
          {...restProps}
          value={values[field] || defaultValue || ''}
          onChange={(e: SelectChangeEvent<any>) => onChange(field, e.target.value)}
        >
          {options.map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      )
    }

    return null
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {Object.entries(config).map(([field, fieldConfig]) => {
        if (visibilityRules && visibilityRules[field] === false) {
          return null
        }

        return (
          <Paper
            key={field}
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: 2,
              border: '1px solid',
              borderColor: 'grey.300',
              borderRadius: 2,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
              <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {fieldConfig.componentProps?.label || field}
              </Typography>

              {renderField({ fieldConfig, field, values, onChange })}

              <Box sx={{ margin: -2, paddingTop: 2 }}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: fieldConfig.description }}
                  component="div"
                  sx={{
                    fontSize: 12,
                    color: 'text.secondary',
                    backgroundColor: 'grey.100',
                    p: 1,
                    px: 2.5,
                    borderBottomRadius: 4,
                  }}
                />
              </Box>
            </Box>
          </Paper>
        )
      })}
    </Box>
  )
}

export default GenericCredentialsForm
