import React from 'react'
import {
  Alert,
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { CdpProviderErrors } from '../provider/CdpProvider'

type ErrorsProps = {
  errors: CdpProviderErrors
}

export const Errors: React.FC<ErrorsProps> = ({ errors }) => {
  const [open, setOpen] = React.useState(true)

  // Collect all error messages and issues from the errors prop
  const errorEntries = Object.entries(errors)
    .filter(([, value]) => value !== null) // Filter out null errors
    .map(([key, value]) => ({
      key,
      message: value?.message || 'An unknown error occurred.',
      issues: value?.error?.issues || [],
    }))

  if (errorEntries.length === 0) {
    return null
  }

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Collapse in={open}>
        <Alert
          severity="error"
          role="alert"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpen(false)}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {errorEntries.map(({ key, message, issues }) => (
            <Box key={key}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {message}
              </Typography>
              {issues.length > 0 && (
                <List dense>
                  {issues.map((issue, idx) => (
                    <ListItem key={`${key}-${idx}`} disablePadding>
                      <ListItemText
                        primary={`Path: ${issue.path.join('.')}`}
                        secondary={issue.message}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          ))}
        </Alert>
      </Collapse>
    </Box>
  )
}
