import React from 'react'
import { createId } from '@paralleldrive/cuid2'
import { Box, Switch, TextField, Typography } from '@mui/material'
import { renderFormComponent, transformFormData } from 'components/chord-cdp/utils/forms'
import { coreDestinations } from 'components/chord-cdp/destinations'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import { useCdp } from 'components/chord-cdp/provider/CdpProvider'
import { Error } from 'components/chord-cdp/overview/Error'
import { FormSheet } from 'components/common'
import { Button } from '@chordco/component-library'
import { Check as CheckIcon, Error as ErrorIcon } from '@mui/icons-material'

type EditDestinationFormProps = {
  destination: Record<string, any>
  onDestinationEdited: () => void
  onClose: () => void
}

const EditDestinationForm: React.FC<EditDestinationFormProps> = ({
  destination,
  onDestinationEdited,
  onClose,
}) => {
  const {
    state: { loadingStates, errors },
    editDestination,
    testDestination,
    clearError,
  } = useCdp()

  const [testStatus, setTestStatus] = React.useState<string | null>(null)

  const coreDestination = coreDestinations.find(d => d.id === destination.destinationType)

  if (!coreDestination) {
    // eslint-disable-next-line no-console
    console.error(`Could not find destination with type ${destination.destinationType}`)
    return null
  }

  const credentials = coreDestination?.credentials as CredentialsConfig

  // Initialize formData from credentials default values
  const [formData, setFormData] = React.useState<Record<string, any>>(() => {
    const defaultFormData = credentials
      ? Object.fromEntries(
          Object.entries(credentials).map(([key, config]) => [
            key,
            config.renderComponent === Switch
              ? config.componentProps?.defaultChecked ?? false
              : config.componentProps?.defaultValue || '',
          ])
        )
      : {}

    // Merge default values with actual values from the destination we want to edit
    const actualFormData = {
      ...defaultFormData,
      ...{
        ...destination,
        // Convert parameters object to array of key-value pairs
        parameters:
          destination.parameters === null || destination.parameters === undefined
            ? []
            : Object.entries(destination.parameters).map(([key, value]) => ({
                id: createId(),
                key,
                value,
              })),
      },
    }

    return {
      ...actualFormData,
      id: destination.id,
      type: 'destination',
      destinationType: destination.destinationType,
    }
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = transformFormData(formData, credentials)
    const result = await editDestination(destination.id, data)
    if (result === true) onDestinationEdited()
  }

  const handleChange = (field: string, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleClose = () => {
    clearError('editDestination')
    clearError('testDestination')
    onClose()
  }

  const handleTestConnection = async () => {
    const data = transformFormData(formData, credentials)
    const result = await testDestination(data)
    setTestStatus(result === true ? 'success' : 'error')
  }

  const handleCloseAddError = () => {
    clearError('addDestination')
  }

  const handleCloseTestError = () => {
    clearError('testDestination')
    setTestStatus(null)
  }

  return (
    <FormSheet
      onSubmit={handleSubmit}
      onClose={handleClose}
      customTitle={`Edit ${coreDestination?.title} Destination`}
      isEdit={true}
      isLarge={true}
      isLoading={loadingStates.editDestination}
      submitDisabled={loadingStates.editDestination}
      name="Edit Destination"
      location="Chord CDP"
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
          <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
            Destination Name
          </Typography>
          <TextField
            label="Destination Name"
            onChange={e => handleChange('name', e.target.value)}
            value={formData.name}
            variant="outlined"
            required
          />
        </Box>

        {renderFormComponent(coreDestination, formData, handleChange)}

        {coreDestination.usesBulker && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleTestConnection}
            name="Test Edit Connection"
            location="Chord CDP"
            style={{ alignSelf: 'flex-end' }}
            isLoading={loadingStates.testDestination}
            disabled={loadingStates.testDestination}
            icon={testStatus === 'success' ? CheckIcon : testStatus === 'error' ? ErrorIcon : null}
          >
            {testStatus === 'success'
              ? 'Connection Successful'
              : testStatus === 'error'
              ? 'Connection Failed'
              : 'Test Connection'}
          </Button>
        )}

        {errors.addDestination && (
          <Error error={errors.addDestination} onClose={handleCloseAddError} />
        )}

        {errors.testDestination && (
          <Error error={errors.testDestination} onClose={handleCloseTestError} />
        )}
      </Box>
    </FormSheet>
  )
}

export default EditDestinationForm
