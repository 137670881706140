import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Overview } from './overview/Overview'
import { Header } from 'components/common'
import { IncomingEvents } from './events/incoming'
import { DestinationEvents } from './events/destinations'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cdp-tabpanel-${index}`}
      aria-labelledby={`cdp-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `cdp-tab-${index}`,
    'aria-controls': `cdp-tabpanel-${index}`,
  }
}

export const ChordCdp: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setTabIndex(tabIndex)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Header title="Chord CDP" />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChange} aria-label="cdp tabs">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Incoming Events" {...a11yProps(1)} />
          <Tab label="Destination Events" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Overview />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <IncomingEvents />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <DestinationEvents />
      </TabPanel>
    </Box>
  )
}
