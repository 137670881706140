import React from 'react'
import { TextField, Select } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const facebookPixelCredentialsConfig: CredentialsConfig = {
  pixelId: {
    description: 'Facebook Pixel ID',
    renderComponent: TextField,
    componentProps: {
      label: 'Pixel ID',
      type: 'text',
      required: true,
    },
  },
  accessToken: {
    description: 'Facebook Access Token',
    renderComponent: TextField,
    componentProps: {
      label: 'Access Token',
      type: 'password',
      required: true,
    },
  },
  actionSource: {
    description: 'Action Source',
    renderComponent: Select,
    componentProps: {
      label: 'Action Source',
      defaultValue: 'website',
      fullWidth: true,
      required: true,
      options: [
        { id: 'website', value: 'Website' },
        { id: 'app', value: 'App' },
        { id: 'phone_call', value: 'Phone Call' },
        { id: 'chat', value: 'Chat' },
        { id: 'physical_store', value: 'Physical Store' },
        { id: 'system_generated', value: 'System Generated' },
        { id: 'other', value: 'Other' },
      ],
    },
  },
  events: {
    description: `List of events to send, delimited by comma. Following <code>page</code>, <code>screen</code>, or any arbitrary event (name of <code>track</code> event).
Special values: <b>empty string</b> - send only <code>track</code> events, <b>*</b> - send all events useful if you want to filter events with Functions `,
    renderComponent: TextField,
    componentProps: {
      label: 'Events',
      type: 'text',
    },
  },
  phoneFieldName: {
    description:
      "Name of the field in the event user traits that contains the phone number. Expected format could be <a href='https://en.wikipedia.org/wiki/E.164' target='_blank' rel='noreferrer noopener'>E.164</a> or international format. If empty, phone number hash will not be sent.",
    renderComponent: TextField,
    componentProps: {
      label: 'Phone Field Name',
      type: 'text',
    },
  },
}

export const FacebookCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={facebookPixelCredentialsConfig} />
)
