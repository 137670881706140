import React, { useEffect } from 'react'
import { useIsMobile } from 'hooks'
import { Timezone } from 'components/data_settings/timezone'
import { WeekDefinition } from './weekDefinition'
import { Scheduling } from './scheduling'
import ChangeLog from './changeLog'
import { Button, H1, H2 } from '@chordco/component-library'
import styled from 'styled-components'
import { useDataSettingsData } from 'redux/state/dataSettings'
import { useIsLoading } from 'redux/utils'
import { Loading } from 'components/common'

export const DataSettings = () => {
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const { dataSettings, getDataSettings, timezones, getDataTimezones } = useDataSettingsData()

  const isLoading = useIsLoading('getDataSettings', 'getDataTimezones')

  useEffect(() => {
    const fetchAsyncData = async () => {
      await Promise.all([getDataSettings(), getDataTimezones()])
    }

    fetchAsyncData()
  }, [])

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && dataSettings && timezones && (
        <>
          <HeadingContainer>
            <Heading>Data Setup</Heading>
            <SubHeading>Changes will not take effect until next refresh</SubHeading>
            <ButtonContainer>
              <Button
                name="Save Changes"
                location="Data Setting Page"
                type="submit"
                purpose="primary"
                centreContent={true}
              >
                Save Changes
              </Button>
            </ButtonContainer>
          </HeadingContainer>
          <DataSettingContainer>
            <Timezone
              timezone={dataSettings.timezone}
              lookerTimezones={timezones['united_states_timezones']}
            />

            <WeekDefinition startOfWeek={dataSettings.startOfWeek.label} />

            <Scheduling
              startOfYear={dataSettings.startOfYear.label}
              fiscalPeriod={dataSettings.fiscalPeriod}
            />

            <ChangeLog />
          </DataSettingContainer>
        </>
      )}
    </>
  )
}

const DataSettingContainer = styled.div`
  padding-top: 15px;
`

const HeadingContainer = styled.div`
  position: relative;
  padding-bottom: 30px;
`
const SubHeading = styled.div`
  font-size: 14px;
  color: ${p => p.theme.ContentTertiary};
  margin-top: 10px;
`
const ButtonContainer = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
  color: ${p => p.theme.ContentTertiary};
`
