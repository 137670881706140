import React from 'react'
import { TextField, Switch } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const juneSdkCredentialsConfig: CredentialsConfig = {
  apiKey: {
    description: `To get or create an API Key, go to workspace's "Settings & integrations" > Integrations > June SDK`,
    renderComponent: TextField,
    componentProps: {
      label: 'API Key',
      type: 'text',
      required: true,
    },
  },
  enableAnonymousUserProfiles: {
    description: 'If enabled, anonymous users will be tracked in June',
    renderComponent: Switch,
    componentProps: {
      label: 'Enable Anonymous User Profiles',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
}

export const JuneSdkCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={juneSdkCredentialsConfig} />
)
