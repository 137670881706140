import React from 'react'
import { TextField, Select } from '@mui/material'
import { CredentialsConfig } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const webhookCredentialsConfig: CredentialsConfig = {
  url: {
    description: 'Webhook URL',
    renderComponent: TextField,
    componentProps: {
      label: 'URL',
      type: 'url',
      required: true,
    },
  },
  method: {
    description:
      'HTTP method. Can be <code>GET</code>, <code>POST</code>, <code>PUT</code>, <code>DELETE</code>',
    renderComponent: Select,
    componentProps: {
      label: 'HTTP Method',
      defaultValue: 'POST',
      fullWidth: true,
      required: true,
      options: [
        { id: 'GET', value: 'GET' },
        { id: 'POST', value: 'POST' },
        { id: 'PUT', value: 'PUT' },
        { id: 'DELETE', value: 'DELETE' },
      ],
    },
  },
  headers: {
    description: 'List of headers in format <code>key: value</code>',
    renderComponent: TextField,
    componentProps: {
      label: 'Headers',
      type: 'text',
      multiline: true,
      rows: 10,
    },
  },
}

export const WebhookCredentialsForm = props => (
  <GenericCredentialsForm {...props} config={webhookCredentialsConfig} />
)
