import React from 'react'
import { TextField, Switch, Select } from '@mui/material'
import { CredentialsConfig, s3Regions } from 'components/chord-cdp/configs'
import GenericCredentialsForm from '../GenericCredentialsForm'

export const redshiftCredentialsConfig: CredentialsConfig = {
  authenticationMethod: {
    description:
      "Redshift authentication method: <a target='_blank' rel='noopener noreferrer' href='https://docs.jitsu.com/destinations/warehouse/redshift#advanced-iam-role-for-jitsu'>IAM Role based</a> or database username/password",
    renderComponent: Select,
    componentProps: {
      label: 'Authentication Method',
      defaultValue: 'password',
      fullWidth: true,
      required: true,
      options: [
        { id: 'password', value: 'Password' },
        { id: 'iam', value: 'IAM Role' },
      ],
    },
  },
  serverless: {
    description: 'Redshift Serverless',
    renderComponent: Switch,
    componentProps: {
      label: 'Redshift Serverless',
      type: 'checkbox',
      defaultChecked: false,
    },
  },
  region: {
    description: 'AWS Region',
    renderComponent: Select,
    componentProps: {
      label: 'AWS Region',
      defaultValue: 'us-east-1',
      fullWidth: true,
      required: true,
      options: s3Regions.map(region => ({ id: region, value: region })),
    },
  },
  clusterIdentifier: {
    description: 'Redshift cluster identifier',
    renderComponent: TextField,
    componentProps: {
      label: 'Cluster Identifier',
      type: 'text',
    },
  },
  workgroupName: {
    description: 'Redshift Serverless workgroup name',
    renderComponent: TextField,
    componentProps: {
      label: 'Workgroup Name',
      type: 'text',
    },
  },
  roleARN: {
    description:
      "IAM role ARN. <a target='_blank' rel='noopener noreferrer' href='https://docs.jitsu.com/destinations/warehouse/redshift#advanced-iam-role-for-jitsu'>How to create</a>",
    renderComponent: TextField,
    componentProps: {
      label: 'Role ARN',
      type: 'text',
    },
  },
  externalID: {
    description: 'IAM external ID',
    renderComponent: TextField,
    componentProps: {
      label: 'IAM External ID',
      type: 'text',
    },
  },
  host: {
    description: 'Redshift host',
    renderComponent: TextField,
    componentProps: {
      label: 'Host',
      type: 'text',
    },
  },
  database: {
    description: 'Redshift database name',
    renderComponent: TextField,
    componentProps: {
      label: 'Database',
      type: 'text',
      required: true,
    },
  },
  defaultSchema: {
    description: 'Redshift schema',
    renderComponent: TextField,
    componentProps: {
      label: 'Schema',
      type: 'text',
      defaultValue: 'PUBLIC',
    },
  },
  username: {
    description: 'Redshift username',
    renderComponent: TextField,
    componentProps: {
      label: 'Username',
      type: 'text',
    },
  },
  password: {
    description: 'Redshift password',
    renderComponent: TextField,
    componentProps: {
      label: 'Password',
      type: 'password',
    },
  },
  bucket: {
    description: 'S3 Bucket Name',
    renderComponent: TextField,
    componentProps: {
      label: 'S3 Bucket Name',
      type: 'text',
      required: true,
    },
  },
  accessKeyId: {
    description:
      "S3 Access Key Id. <a target='_blank' rel='noreferrer noopener' href='https://docs.aws.amazon.com/IAM/latest/UserGuide/security-creds.html#access-keys-and-secret-access-keys'>Create access key</a>",
    renderComponent: TextField,
    componentProps: {
      label: 'S3 Access Key ID',
      type: 'text',
    },
  },
  secretAccessKey: {
    description: 'S3 Secret Access Key',
    renderComponent: TextField,
    componentProps: {
      label: 'S3 Secret Access Key',
      type: 'password',
    },
  },
}

const getVisibilityRules = (values: Record<string, any>) => ({
  clusterIdentifier: values.authenticationMethod === 'iam' && !values.serverless,
  workgroupName: values.authenticationMethod === 'iam' && values.serverless,
  roleARN: values.authenticationMethod === 'iam',
  externalID: values.authenticationMethod === 'iam',
  secretAccessKey: values.authenticationMethod !== 'iam',
  accessKeyId: values.authenticationMethod !== 'iam',
  host: values.authenticationMethod !== 'iam',
  username: values.authenticationMethod !== 'iam' || !values.serverless,
  password: values.authenticationMethod !== 'iam',
})

export const RedshiftCredentialsForm = props => (
  <GenericCredentialsForm
    {...props}
    config={redshiftCredentialsConfig}
    getVisibilityRules={getVisibilityRules}
  />
)

