import { AxiosRequestConfig, hubClient } from '../http'
import { DataSettings, Timezones } from 'types'

export interface DataSettingsClient {
  getDataSettings: (tenantId: number) => Promise<DataSettings>
  getDataTimezones: (tenantId: number) => Promise<Timezones>
}

export const dataSettingsClient: DataSettingsClient = {
  getDataSettings: tenantId =>
    hubClient.get(`tenants/${tenantId}/data_settings/tenant_data_settings`),
  getDataTimezones: tenantId => {
    const config: AxiosRequestConfig = { skipCamelize: true }
    return hubClient.get(`tenants/${tenantId}/data_settings/looker_timezone_settings`, config)
  },
}
