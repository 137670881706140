import React, { ReactNode, RefObject, useEffect } from 'react'
import styled from 'styled-components'
import { standardIcons } from '@chordco/component-library'
import OverviewHeader from './OverviewHeader'
import { EmptyView } from './EmptyView'
import { /*Api, */ Language, Storage } from '@mui/icons-material'

const CDPLogo = standardIcons.CDPLogo

export type ConnectorNode = {
  id: string
  card: (forceSelect: boolean) => ReactNode
}

export type ConnectionDiagramProps = {
  sources: ConnectorNode[]
  destinations: ConnectorNode[]
  connectorSources: ConnectorNode[]
  connections: {
    from: string
    to: string
  }[]
  onAddNewConnector: () => void
  onAddNewSource: () => void
  onAddNewDestination: () => void
  canAddNewConnector?: boolean
  canAddNewSource?: boolean
  canAddNewDestination?: boolean
}

export type Point = { left: number; top: number }

export type ConnectorLine = {
  from: Point
  to: Point
  selected: boolean
}

export function getRelativePosition(parent: Element, child: Element): Point {
  const parentRect = parent.getBoundingClientRect()
  const childRect = child.getBoundingClientRect()
  return {
    left: childRect.left - parentRect.left,
    top: childRect.top - parentRect.top,
  }
}

export const ConnectionsDiagram: React.FC<ConnectionDiagramProps> = ({
  connections,
  sources,
  connectorSources,
  destinations,
  onAddNewConnector,
  onAddNewSource,
  onAddNewDestination,
  canAddNewConnector,
  canAddNewSource,
  canAddNewDestination,
}) => {
  const canvasRef = React.useRef<HTMLDivElement>(null)
  const srcRefs = React.useRef<RefObject<HTMLElement>[]>([])
  const dstRefs = React.useRef<RefObject<HTMLDivElement>[]>([])
  const connectorsRef = React.useRef<RefObject<HTMLElement>[]>([])
  const logoRef = React.useRef<HTMLDivElement>(null)
  const [windowSize, setWindowSize] = React.useState<
    { width: number; height: number } | undefined
  >()
  const [lines, setLines] = React.useState<ConnectorLine[]>([])
  const [mouseOverSrc, setMouseOverSrc] = React.useState<string | undefined>()
  const [mouseOverDst, setMouseOverDst] = React.useState<string | undefined>()
  const [forceSelectDestination, setForceSelectDestination] = React.useState<string[]>([])
  const [forceSelectSource, setForceSelectSource] = React.useState<string[]>([])
  const emptySitesRef = React.useRef<HTMLDivElement>(null)
  const emptyConnectorsRef = React.useRef<HTMLDivElement>(null)
  const emptyDestinationsRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const resizeListener = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    window.addEventListener('resize', resizeListener)

    return () => window.removeEventListener('resize', resizeListener)
  })

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`Rendering connections diagram with ${connections.length} connections`)

    if (canvasRef.current == null || logoRef.current == null) {
      return
    }

    const logoPosition = getRelativePosition(canvasRef.current, logoRef.current)
    const logoBounds = logoRef.current.getBoundingClientRect()
    const newLines: ConnectorLine[] = []
    srcRefs.current
      .map(r => r.current)
      .filter(r => !!r)
      .forEach((r, idx) => {
        if (!r) return
        if (!canvasRef.current) return

        const rel = getRelativePosition(canvasRef.current, r)
        const bounds = r.getBoundingClientRect()
        const source = sources[idx]
        const selected =
          mouseOverSrc === source.id ||
          (!!mouseOverDst && !!connections.find(c => c.from === source.id && c.to === mouseOverDst))
        if (connections.find(c => c.from === source.id)) {
          // eslint-disable-next-line no-console
          console.log(`Adding connector from ${source.id} to logo (select=${selected})`)
          newLines.push({
            from: { top: rel.top + bounds.height / 2, left: rel.left + bounds.width },
            to: { left: logoPosition.left, top: logoPosition.top + logoBounds.height / 2 },
            selected,
          })
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Source ${source.id} has no connections`)
        }
      })

    connectorsRef.current
      .map(r => r.current)
      .filter(r => !!r)
      .forEach((r, idx) => {
        if (!r) return
        if (!canvasRef.current) return

        const rel = getRelativePosition(canvasRef.current, r)
        const bounds = r.getBoundingClientRect()
        const source = connectorSources[idx]
        const selected =
          mouseOverSrc === source.id ||
          (!!mouseOverDst && !!connections.find(c => c.from === source.id && c.to === mouseOverDst))
        if (connections.find(c => c.from === source.id)) {
          newLines.push({
            from: { top: rel.top + bounds.height / 2, left: rel.left + bounds.width },
            to: { left: logoPosition.left, top: logoPosition.top + logoBounds.height / 2 },
            selected,
          })
        }
      })
    dstRefs.current
      .map(r => r.current)
      .filter(r => !!r)
      .forEach((r, idx) => {
        if (!r) return
        if (!canvasRef.current) return

        const rel = getRelativePosition(canvasRef.current, r)
        const bounds = r.getBoundingClientRect()
        const destination = destinations[idx]
        const selected =
          mouseOverDst === destination.id ||
          (!!mouseOverSrc &&
            !!connections.find(c => c.to === destination.id && c.from === mouseOverSrc))
        if (selected) {
          setForceSelectDestination([destination.id])
          setForceSelectSource(connections.filter(c => c.to === destination.id).map(c => c.from))
        }
        if (connections.find(c => c.to === destination.id)) {
          newLines.push({
            to: { top: rel.top + bounds.height / 2, left: rel.left },
            from: {
              left: logoPosition.left + logoBounds.width,
              top: logoPosition.top + logoBounds.height / 2,
            },
            selected,
          })
        }
      })
    if (emptySitesRef.current) {
      const rel = getRelativePosition(canvasRef.current, emptySitesRef.current)
      const bounds = emptySitesRef.current.getBoundingClientRect()
      newLines.push({
        from: { top: rel.top + bounds.height / 2, left: rel.left + bounds.width },
        to: { left: logoPosition.left, top: logoPosition.top + logoBounds.height / 2 },
        selected: false,
      })
    }
    if (emptyConnectorsRef.current) {
      const rel = getRelativePosition(canvasRef.current, emptyConnectorsRef.current)
      const bounds = emptyConnectorsRef.current.getBoundingClientRect()
      newLines.push({
        from: { top: rel.top + bounds.height / 2, left: rel.left + bounds.width },
        to: { left: logoPosition.left, top: logoPosition.top + logoBounds.height / 2 },
        selected: false,
      })
    }
    if (emptyDestinationsRef.current) {
      const rel = getRelativePosition(canvasRef.current, emptyDestinationsRef.current)
      const bounds = emptyDestinationsRef.current.getBoundingClientRect()
      newLines.push({
        to: { top: rel.top + bounds.height / 2, left: rel.left },
        from: {
          left: logoPosition.left + logoBounds.width,
          top: logoPosition.top + logoBounds.height / 2,
        },
        selected: false,
      })
    }
    setLines(newLines)

    if (mouseOverSrc) {
      setForceSelectSource([mouseOverSrc])
      setForceSelectDestination(connections.filter(c => c.from === mouseOverSrc).map(c => c.to))
    } else if (mouseOverDst) {
      setForceSelectDestination([mouseOverDst])
      setForceSelectSource(connections.filter(c => c.to === mouseOverDst).map(c => c.from))
    } else {
      setForceSelectDestination([])
      setForceSelectSource([])
    }
  }, [connections, sources, destinations, windowSize, mouseOverSrc, mouseOverDst])

  srcRefs.current = sources.map((_, i) => srcRefs.current[i] ?? React.createRef())
  dstRefs.current = destinations.map((_, i) => dstRefs.current[i] ?? React.createRef())
  connectorsRef.current = connectorSources.map(
    (_, i) => connectorsRef.current[i] ?? React.createRef()
  )

  const connectorSection = (
    <React.Fragment key="connectors">
      {connectorSources?.length > 0 && (
        <>
          <OverviewHeader
            title="Connectors"
            hasData={connectorSources?.length > 0}
            onAddNew={onAddNewConnector}
            canAddNew={canAddNewConnector}
          />
          {connectorSources.map((s, idx) => (
            <div
              key={s.id + idx}
              ref={connectorsRef.current[idx] as any}
              className="cursor-pointer mb-4"
              onMouseOver={() => {
                setMouseOverSrc(s.id)
              }}
              onMouseLeave={() => setMouseOverSrc(undefined)}
            >
              {s.card(forceSelectSource.includes(s.id))}
            </div>
          ))}
        </>
      )}

      {/* TODO: ommit the Connector section for now since it cannot be added by the user */}
      {/* {connectorSources?.length === 0 && (
        <EmptyConnectorsContainer ref={emptyConnectorsRef}>
          <OverviewHeader
            title="Connectors"
            hasData={false}
            onAddNew={onAddNewConnector}
            canAddNew={canAddNewConnector}
          />
          <EmptyView
            title="Create your first connector"
            description="Connectors are used to pull data from external sources like databases, APIs, files, etc."
            icon={<Api sx={{ fontSize: 64, color: 'grey.500' }} />}
          />
        </EmptyConnectorsContainer>
      )} */}
    </React.Fragment>
  )

  const sitesSection = (
    <React.Fragment key="sites">
      {sources?.length > 0 && (
        <>
          <OverviewHeader
            title="Sites"
            hasData={sources?.length > 0}
            onAddNew={onAddNewSource}
            canAddNew={canAddNewSource}
          />
          {sources.map((s, idx) => (
            <SiteContainer
              key={s.id + idx}
              ref={srcRefs.current[idx] as any}
              onMouseOver={() => {
                setMouseOverSrc(s.id)
              }}
              onMouseLeave={() => setMouseOverSrc(undefined)}
            >
              {s.card(forceSelectSource.includes(s.id))}
            </SiteContainer>
          ))}
        </>
      )}

      {sources?.length === 0 && (
        <EmptySitesContainer ref={emptySitesRef}>
          <OverviewHeader
            title="Sites"
            hasData={false}
            onAddNew={onAddNewSource}
            canAddNew={canAddNewSource}
          />
          <EmptyView
            title="Create your first site"
            description="Site (or stream) is a source of events which are being pushed to Chord via SDK. It could be a website, web application, mobile application or backend server"
            icon={<Language sx={{ fontSize: 64, color: 'grey.500' }} />}
          />
        </EmptySitesContainer>
      )}
    </React.Fragment>
  )
  return (
    <Wrapper ref={canvasRef}>
      <RowContainer>
        <ListContainer>{[sitesSection, connectorSection]}</ListContainer>
        <CenteredContainer>
          <LogoContainer ref={logoRef}>
            <CDPLogo />
          </LogoContainer>
        </CenteredContainer>
        <ListContainer>
          {destinations?.length > 0 && (
            <>
              <OverviewHeader
                title="Destinations"
                hasData={destinations?.length > 0}
                onAddNew={onAddNewDestination}
                canAddNew={canAddNewDestination}
              />
              {destinations.map((dest, idx) => (
                <DestinationsContainer
                  key={dest.id}
                  ref={dstRefs.current[idx]}
                  onMouseOver={() => setMouseOverDst(dest.id)}
                  onMouseLeave={() => setMouseOverDst(undefined)}
                >
                  {dest.card(forceSelectDestination.includes(dest.id))}
                </DestinationsContainer>
              ))}
            </>
          )}

          {destinations?.length === 0 && (
            <EmptyDestinationsContainer ref={emptyDestinationsRef}>
              <OverviewHeader
                title="Destinations"
                hasData={false}
                onAddNew={onAddNewDestination}
                canAddNew={canAddNewDestination}
              />
              <EmptyView
                title="Create your first destination"
                description="Destination is a database or service which accepts data coming from sites or connector"
                icon={<Storage sx={{ fontSize: 64, color: 'grey.500' }} />}
              />
            </EmptyDestinationsContainer>
          )}
        </ListContainer>
      </RowContainer>
      <StyledSVG>
        {lines
          .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? 1 : -1))
          .map((line, i) => {
            const lineCurves = `C${
              line.from.left + Math.abs(line.from.left - line.to.left) * 0.75
            },${line.from.top} ${line.to.left - Math.abs(line.from.left - line.to.left) * 0.75},${
              line.to.top
            } ${line.to.left},${line.to.top}`
            const path = `M${line.from.left},${line.from.top} ${lineCurves}`
            return <StyledPath key={i} d={path} selected={line.selected} />
          })}
      </StyledSVG>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 2;
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 100px);
`

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  padding: 0 36px;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DestinationsContainer = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
`

const EmptyDestinationsContainer = styled.div`
  margin-bottom: 12px;
`

const EmptySitesContainer = styled.div`
  margin-bottom: 12px;
`

// const EmptyConnectorsContainer = styled.div`
//   margin-top: 12px;
//   margin-bottom: 12px;
// `

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

const SiteContainer = styled.div`
  cursor: pointer;
  margin-bottom: 12px;
`

const StyledPath = styled.path<{ selected: boolean }>`
  fill: none;
  stroke: currentColor;
  stroke-width: ${({ selected }) => (selected ? 8 : 6)};
  transition: color 0.3s;
  color: ${({ selected }) => (selected ? 'var(--Primary40)' : 'var(--Primary10)')};
`
