import React, { useState } from 'react'
import { Button, H3, Label, standardIcons } from '@chordco/component-library'
import styled from 'styled-components'
import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { TimezoneValue } from 'types'

type TimezoneProps = {
  timezone: string
  lookerTimezones: Record<string, TimezoneValue>
}

export const Timezone: React.FC<TimezoneProps> = ({ timezone, lookerTimezones }) => {
  const [selectedTimeZone, setSelectedTimezone] = useState<string>(timezone)

  const handleTimezoneChange = event => {
    setSelectedTimezone(event.target.value)
  }

  return (
    <>
      <TimezoneContainer>
        <TitleContainer>
          <H3>Time Zone</H3>
          <DefaultTimeZone> Default America - New York </DefaultTimeZone>
          <ButtonsContainer>
            <Button
              type="button"
              purpose="ghost"
              icon={standardIcons.IconLeft}
              name="Reset to Default"
              location="Data Settings"
              size="small"
            >
              Reset to Default
            </Button>
          </ButtonsContainer>
        </TitleContainer>

        <Label>Company data time zone</Label>
        <FormControl sx={{ minWidth: 350 }} size="small">
          <Select id="timezone-picker" value={selectedTimeZone} onChange={handleTimezoneChange}>
            {Object.entries(lookerTimezones).map(([key]) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BoxContainer>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>
        </BoxContainer>
      </TimezoneContainer>
    </>
  )
}

const TimezoneContainer = styled.div`
  position: relative;
  top: 10px;
  margin-left: 10px;
  padding-bottom: 25px;
`
const TitleContainer = styled.div`
  position: relative;
  top: 10px;
  padding-bottom: 20px;
`

const DefaultTimeZone = styled.span`
  position: absolute;
  top: 10px;
  right: 165px;
  font-size: 12px;
  color: ${p => p.theme.ContentTertiary};
`

const ButtonsContainer = styled.span`
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 10px;
`

const BoxContainer = styled.div`
  padding-top: 40px;
`
